import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { ConfirmPopup } from 'primereact/confirmpopup';


const NuevoFolioTitulares = (props: any) => {
    const user = authGuardService.getUser()

    const prop_id = props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
        props.responseData ? props.responseData.prop_id :
            props.previousFolio ? props.previousFolio[0].prop_id :
                props.generalData ? props.generalData.prop_id :
                    props.propiedadDetail ? props.propiedadDetail.prop_id :
                        0

    const columns2 = [
        { field: 'RowNumber', header: 'Reg' },
        { field: 'prop_clave', header: 'Clave' },
        { field: 'prop_folio', header: 'Folio' },
        { field: 'prop_descripcion', header: 'Dirección' },
        { field: 'prop_lt', header: 'Lt' },
        { field: 'prop_mz', header: 'Mz' },
    ];

    const [titulares, setTitulares] = useState([])
    const [nuevoFolioModal, setNuevoFolioModal] = useState(false)

    const [data, setData]: any = useState({
        paterno: { value: '', valid: false },
        materno: { value: '', valid: false },
        nombre: { value: '', valid: false },
        rfc: { value: '', valid: false },
        curp: { value: '', valid: false },
        nuda: { value: 0, valid: false },
        usufructo: { value: 0, valid: false },
        representacion: { value: '', valid: false },
    })

    useEffect(() => {
        getTitulares()
    }, [])


    const getTitulares = () => {
        let datos = {
            frmu_id: props && props.information && props.information.frmu_id,
        }
        ApiServices.apiPost('getPropiedadDT', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    const formatted = res.data.addenda.map((singleTitular, index) => {
                        singleTitular.temporalID = index + 1000
                        singleTitular.ptit_status = 1
                        return singleTitular
                    })
                    props.handleValueChange(formatted, props.indexes[0], props.indexes[1])
                    setTitulares(formatted)
                }
            }
        }).catch(error => {

        })
    }

    const addNewTitular = () => {
        if (titulares.length > 0) {
            const lastObject = titulares[titulares.length - 1];
            const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                acc[key] = '';
                return acc;
            }, {});

            emptyObject.temporalID = titulares.length + 1000
            emptyObject.usu_id = user.usu_id.value
            emptyObject.frmu_id = props.information.frmu_id
            emptyObject.prop_antecedente = props && props.propiedadDetail && props.propiedadDetail.prop_folio ? `Procede del Folio ${props && props.propiedadDetail && props.propiedadDetail.prop_folio}` : 'PRIMER ANTECEDENTE'
            emptyObject.RowNumber = titulares.length + 1
            emptyObject.ptit_status = 1

            setTitulares([...titulares, emptyObject]);
            props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
        } else {
            const newTitular = {
                temporalID: titulares.length + 1000,
                frmu_id: props.information.frmu_id,
                usu_id: user.usu_id.value,
                prop_antecedente: props && props.propiedadDetail && props.propiedadDetail.prop_folio ? `Procede del Folio ${props && props.propiedadDetail && props.propiedadDetail.prop_folio}` : 'PRIMER ANTECEDENTE',
                prop_clave: '',
                RowNumber: titulares.length + 1,
                cp_id: '',
                prop_calle: '',
                prop_ext: '',
                prop_int: '',
                prop_medidas: '',
                prop_constr: '',
                prop_suptotal: '',
                prop_zona: '',
                prop_condo: '',
                prop_descripcion: '',
                prop_lote_cond: '',
                prop_lt: '',
                prop_edificio: '',
                prop_libro: '',
                prop_vol: '',
                prop_secc: '',
                prop_serie: '',
                prop_reg: '',
                prop_foja: '',
                prop_mz: '',
                cp_edo: '',
                cp_mun: '',
                prop_direccion: '',
                prop_indiviso: '',
                prop_ususuelo: '',
                ptit_status: 1
            }
            setTitulares([...titulares, newTitular]);
            props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData && rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminará"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        if (options.field == 'ptit_nuda' || options.field == "ptit_usufructo" || options.field == 'nuda_enaje' || options.field == 'usufructo_enaje') {
            return (
                <InputNumber
                    value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)} maxFractionDigits={2} minFractionDigits={2} min={0} max={100} />
            )
        } else if (options.field == 'prop_descripcion') {
            return (
                <InputTextarea
                    value={options.value}
                    onChange={(e) => options.editorCallback(e.target.value)}
                    onKeyDown={(e) => e.stopPropagation()}
                    rows={5}
                    cols={30}
                    style={{ resize: 'none' }}
                />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    const handleChangeTitulares = (index: string | number, field: any, value: any) => {
        const newTitulares = [...titulares];
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        setTitulares(newTitulares);
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; }) => {
        handleChangeTitulares(e.rowIndex, e.field, e.newValue)
    };

    const deleteTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.propdt_id == 0 || !data.propdt_id) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 0
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const recoverTitular = (data, index) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (data.propdt_id == 0) {
            cloneTitulares.splice(index, 1);
        } else {
            cloneTitulares[index].ptit_status = 1
        }
        setTitulares(cloneTitulares)
        props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1])
    }

    const actionBodyTemplate = (rowData, index) => {
        if (rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return <Button type="button" severity="danger" icon="pi pi-trash" rounded onClick={() => { deleteTitular(rowData, index.rowIndex) }}></Button>;
    };

    const headerTemplate = (options) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">Adjudica en favor de:</span>
                </div>
                <div>
                    <Button onClick={addNewTitular}>
                        Agregar
                    </Button>
                </div>
            </div>
        );
    };

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            <span className="text-xl text-900 font-bold"></span>
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    useEffect(() => {
        /* if (props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares') */
        /* getTitulares() */
        /* else {
            getTitularesSingle()
        } */
    }, [])

    const [selectedFolio, setSelectedFolio] = useState<any>()
    const [foundedColony, setFoundedColony] = useState([])
    const [selectedCP, setSelectedCP] = useState<any>(null)

    const handleNewFolioTitular = (data: any) => {
        setSelectedFolio(data)
        setNuevoFolioModal(true)
        if (data && data.cp_cp) {
            getColonyWithPostalCode(data.cp_cp, data)
        } else if (props && props.propiedadDetail && props.propiedadDetail.cp_cp) {
            getColonyWithPostalCode(props.propiedadDetail.cp_cp, data)
        } else if (props && props.propiedadDetail && props.propiedadDetail.cp_mun) {
            setEstado(props.propiedadDetail.cp_edo)
            setMunicipio(props.propiedadDetail.cp_mun)
            setCp(props.propiedadDetail.cp_cp)
        }
    }

    const addFolioTemplate = (e) => {
        return <Button label="Editar Folio" severity="secondary" rounded onClick={() => { handleNewFolioTitular(e) }} />;
    };

    const deleteFolioTemplate = (rowData, index) => {
        const [visible, setVisible] = useState(false);
        const toast = useRef(null);
        const buttonEl = useRef(null);

        if (rowData && rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return (
                <>
                    <ConfirmPopup
                        target={buttonEl.current}
                        visible={visible}
                        onHide={() => setVisible(false)}
                        message="¿Estás seguro de eliminar?"
                        acceptLabel='Eliminar'
                        rejectLabel='Cancelar'
                        icon="pi pi-exclamation-triangle"
                        accept={() => { deleteTitular(rowData, index.rowIndex) }}
                    />

                    <Button
                        type="button"
                        severity="danger"
                        icon="pi pi-trash"
                        ref={buttonEl}
                        rounded onClick={() => { setVisible(true) }}>
                    </Button>
                </>
            );
    };

    const handleData = (value, field) => {
        const clone = JSON.parse(JSON.stringify(selectedFolio))
        clone[field] = value
        setSelectedFolio(clone)
    }

    const handleCPChange = (cp: any) => {
        if (cp.length === 5) {
            getColonyWithPostalCode(cp)
        }
    }

    const [estado, setEstado] = useState('')
    const [municipio, setMunicipio] = useState('')
    const [cp, setCp] = useState('')

    const getColonyWithPostalCode = (value: any, information = selectedFolio) => {
        let datos
        datos = { "cp_cp": value }
        ApiServices.apiPost('buscarCP', datos).then(async res => {
            if (res.data.valido === 1) {

                const clone = JSON.parse(JSON.stringify(information))

                setFoundedColony(res.data.addenda)
                setSelectedCP(res.data.addenda[0])
                setEstado(res.data.addenda[0].cp_edo)
                setMunicipio(res.data.addenda[0].cp_mun)
                setCp(res.data.addenda[0].cp_cp)

                clone.cp_mun = res.data.addenda[0].cp_mun
                clone.cp_edo = res.data.addenda[0].cp_edo
                clone.cp_id = res.data.addenda[0].cp_id
                clone.cp_cp = res.data.addenda[0].cp_cp

                setSelectedFolio(clone)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const saveDataInsideArray = (titularesNew = titulares) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titularesNew))
        const newTitulares = cloneTitulares.map((singleTitular) => {
            if (singleTitular.temporalID == selectedFolio.temporalID) {
                if (selectedCP) {
                    setEstado('')
                    setMunicipio('')
                    setCp('')
                }
                return selectedFolio
            } else {
                return singleTitular
            }
        })

        setTitulares(newTitulares)
        props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
        setSelectedFolio(null)
        setNuevoFolioModal(false)
    }


    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={titulares}
                        dataKey="temporalID"
                        paginator
                        rows={5}
                        className="datatable-responsive"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
                        emptyMessage="No hay registros."
                        showGridlines
                        header={header}
                    >
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />
                        {
                            columns2.map(({ field, header }) => {
                                return <Column
                                    key={field}
                                    field={field}
                                    header={header}
                                    editor={(options) => textEditor(options)}
                                    onCellEditComplete={onCellEditComplete}
                                />;
                            })
                        }
                        <Column header="" body={addFolioTemplate}></Column>
                        <Column header="" body={deleteFolioTemplate}></Column>
                        {/* <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={actionBodyTemplate} /> */}
                    </DataTable>
                </div>
            </Panel>

            <Dialog
                header="Folio Real"
                visible={nuevoFolioModal}
                style={{ width: '50vw' }}
                onHide={() => { saveDataInsideArray(titulares) }}
                footer={
                    <div>
                        <Button label="Guardar y Cerrar" onClick={() => { saveDataInsideArray(titulares) }} autoFocus />
                    </div>
                }
            >

                <section className=''>
                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Datos Generales</h5>
                        </div>

                        <Divider />

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_clave"
                                    className="p-inputtext-lg"
                                    placeholder="Clave catastral"
                                    value={selectedFolio && selectedFolio.prop_clave}
                                    onChange={(e) => { handleData(e.target.value, "prop_clave") }}
                                />
                                <label htmlFor="cvecatastral">Clave catastral *</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_antecedente"
                                    className="p-inputtext-lg"
                                    placeholder="Antecedentes"
                                    value={selectedFolio && selectedFolio.prop_antecedente}
                                    onChange={(e) => { handleData(e.target.value, "prop_antecedente") }}
                                />
                                <label htmlFor="cvecatastral">Antecedentes *</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-12'>
                            <span className="p-float-label">
                                <InputTextarea
                                    id="prop_direccion"
                                    className="p-inputtext-lg"
                                    placeholder="Domicilio registral"
                                    value={selectedFolio && selectedFolio.prop_direccion}
                                    onChange={(e) => { handleData(e.target.value, "prop_direccion") }}
                                    rows={5}
                                    cols={30}
                                    style={{ resize: 'none' }}
                                />
                                <label htmlFor="prop_direccion">Domicilio registral</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_cp"
                                    className="p-inputtext-lg"
                                    value={cp}
                                    onChange={(e) => {
                                        handleCPChange(e.target.value);
                                        setCp(e.target.value)
                                    }}
                                />
                                <label htmlFor="cp_cp">Codigo postal</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_edo"
                                    className="p-inputtext-lg"
                                    value={estado}
                                    disabled
                                />
                                <label htmlFor="cp_edo">Estado</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="cp_mun"
                                    className="p-inputtext-lg"
                                    value={municipio}
                                />
                                <label htmlFor="cp_mun">Municipio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_lt"
                                    className="p-inputtext-lg"
                                    placeholder="Lt"
                                    value={selectedFolio && selectedFolio.prop_lt}
                                    onChange={(e) => { handleData(e.target.value, "prop_lt") }}
                                />
                                <label htmlFor="prop_lt">Lt</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_mz"
                                    className="p-inputtext-lg"
                                    placeholder="Mz"
                                    value={selectedFolio && selectedFolio.prop_mz}
                                    onChange={(e) => { handleData(e.target.value, "prop_mz") }}
                                />
                                <label htmlFor="prop_mz">Mz</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_zona"
                                    className="p-inputtext-lg"
                                    placeholder="Zona*"
                                    value={selectedFolio && selectedFolio.prop_zona}
                                    onChange={(e) => { handleData(e.target.value, "prop_zona") }}
                                />
                                <label htmlFor="prop_zona">Zona*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_indiviso"
                                    className="p-inputtext-lg"
                                    placeholder="Indiviso*"
                                    value={selectedFolio && selectedFolio.prop_indiviso}
                                    onChange={(e) => { handleData(e.target.value, "prop_indiviso") }}
                                />
                                <label htmlFor="prop_indiviso">Indiviso</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_ususuelo"
                                    className="p-inputtext-lg"
                                    placeholder="Uso de Suelo*"
                                    value={selectedFolio && selectedFolio.prop_ususuelo}
                                    onChange={(e) => { handleData(e.target.value, "prop_ususuelo") }}
                                />
                                <label htmlFor="prop_ususuelo">Uso de Suelo</label>
                            </span>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Superficie del predio</h5>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_suptotal"
                                    className="p-inputtext-lg"
                                    placeholder="Total m2"
                                    value={selectedFolio && selectedFolio.prop_suptotal}
                                    onChange={(e) => { handleData(e.target.value, "prop_suptotal") }}
                                />
                                <label htmlFor="prop_suptotal">Total m2</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-6'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_constr"
                                    className="p-inputtext-lg"
                                    placeholder="construido m2"
                                    value={selectedFolio && selectedFolio.prop_constr}
                                    onChange={(e) => { handleData(e.target.value, "prop_constr") }}
                                />
                                <label htmlFor="prop_constr">construido m2</label>
                            </span>
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Medidas y Colindancias</h5>
                        </div>
                        <div className='col-12 md:col-12'>
                            <InputTextarea
                                id="prop_medidas"
                                className="p-inputtext-lg"
                                value={selectedFolio && selectedFolio.prop_medidas}
                                onChange={(e) => { handleData(e.target.value, "prop_medidas") }}
                            />
                        </div>
                    </div>

                    <Divider />

                    <div className='grid'>
                        <div className='col-12'>
                            <h5>Domicilio convencional</h5>
                        </div>

                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_calle"
                                    className="p-inputtext-lg"
                                    placeholder="Calle*"
                                    value={selectedFolio && selectedFolio.prop_calle}
                                    onChange={(e) => { handleData(e.target.value, "prop_calle") }}
                                />
                                <label htmlFor="prop_calle">Calle*</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_int"
                                    className="p-inputtext-lg"
                                    placeholder="N°. interior"
                                    value={selectedFolio && selectedFolio.prop_int}
                                    onChange={(e) => { handleData(e.target.value, "prop_int") }}
                                />
                                <label htmlFor="prop_int">N°. interior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_ext"
                                    className="p-inputtext-lg"
                                    placeholder="N°. exterior"
                                    value={selectedFolio && selectedFolio.prop_ext}
                                    onChange={(e) => { handleData(e.target.value, "prop_ext") }}
                                />
                                <label htmlFor="prop_ext">N°. exterior</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_condo"
                                    className="p-inputtext-lg"
                                    placeholder="Condominio"
                                    value={selectedFolio && selectedFolio.prop_condo}
                                    onChange={(e) => { handleData(e.target.value, "prop_condo") }}
                                />
                                <label htmlFor="prop_condo">Condominio</label>
                            </span>
                        </div>
                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_lote_cond"
                                    className="p-inputtext-lg"
                                    placeholder="Lote condominal"
                                    value={selectedFolio && selectedFolio.prop_lote_cond}
                                    onChange={(e) => { handleData(e.target.value, "prop_lote_cond") }}
                                />
                                <label htmlFor="prop_lote_cond">Lote condominal</label>
                            </span>
                        </div>

                        <div className='col-12 md:col-4'>
                            <span className="p-float-label">
                                <InputText
                                    id="prop_edificio"
                                    className="p-inputtext-lg"
                                    placeholder="Edificio"
                                    value={selectedFolio && selectedFolio.prop_edificio}
                                    onChange={(e) => { handleData(e.target.value, "prop_edificio") }}
                                />
                                <label htmlFor="prop_edificio">Edificio</label>
                            </span>
                        </div>


                    </div>
                </section>

            </Dialog>

        </section >
    );

}

export default React.memo(NuevoFolioTitulares);