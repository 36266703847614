import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { Tooltip } from 'primereact/tooltip';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';


const CancelarActo = (props: any) => {
    const user = authGuardService.getUser()
    const toast = useRef<any>(null);

    const prop_id = props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
        props.responseData ? props.responseData.prop_id :
            props.previousFolio ? props.previousFolio[0].prop_id :
                props.generalData ? props.generalData.prop_id :
                    props.propiedadDetail ? props.propiedadDetail.prop_id :
                        0

    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [solicitudMSG, setSolicitudMSG] = useState('')
    const [date2, setDate2] = useState<Date | Date[] | undefined>(undefined);
    const [selected, setSelected] = useState<any>()
    const [allHistroy, setAllHistory] = useState()
    const [searchField, setSearchField] = useState('')
    const [selectedProducts, setSelectedProducts] = useState(null);

    const FirmadoTemplate = (rowData: any) => {
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return (
                <>
                    <Tooltip target=".custom-target-icon2" />
                    <Button
                        label={`Firmado ${rowData.calificador ? ` - ${rowData.calificador}` : ''}`}
                        text
                        severity="secondary"
                        tooltip={`${rowData.calificador ? `${rowData.calificador} - ${rowData.calificador_nombre}` : ''}`}
                        tooltipOptions={{ position: 'top' }}
                        onClick={() => {
                            ModalSetVisible(true)
                            setSelected(rowData)
                        }}
                        style={{ fontSize: 16, width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }}
                    />
                </>
            );
        } else {
            return (
                <p>Sin Firmar</p>
            )
        }
    }

    const EyeTemplate = (rowData: any) => {
        return (
            <>
                <div className='text-center'>
                    <Button className="p-button-text" icon="pi pi-eye" onClick={() => {
                        props.getActoInformationView(rowData)
                        props.setTramiteSelected({ tramite: rowData })
                    }} />
                </div>
            </>
        );
    }

    const dateTemplate = (rowData: any) => {
        if (rowData.trm_fecha) {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_fecha)}`}</p>
                </div>
            );

        } else {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_fecha)}`}</p>
                </div>
            )
        }
    }

    const dateTemplate2 = (rowData: any) => {
        if (rowData.trm_firmado) {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{`${formatearFecha(rowData.trm_firmado)}`}</p>
                </div>
            );

        } else {
            return (
                <div style={{ display: 'flex', justifyItems: 'center', alignContent: 'center', alignItems: 'center' }}>
                    <p>{``}</p>
                </div>
            )
        }
    }

    function filtrarTransacciones(texto: any, fecha: any) {
        if (!fecha && !texto) {
            setAllHistory(props.actoHistory)
        } else {
            const newArray = props.actoHistory.filter((item: any) => {
                if (fecha && item.firstDate !== fecha) return false
                if (texto) {
                    const objectString = JSON.stringify(item);
                    return objectString.includes(texto);
                }
                return true;
            });
            setAllHistory(newArray)
        }
    }

    function convertirFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${año}-${mes}-${dia}T00:00:00.000Z`;
        } else {
            return ''
        }
    }

    function formatearFecha(fechaStr: any) {
        const fecha: any = new Date(fechaStr);
        if (isNaN(fecha)) {
            return "Fecha inválida";
        }
        const dia = fecha.getUTCDate();
        const mes = fecha.getUTCMonth() + 1;
        const año = fecha.getUTCFullYear();
        const fechaFormateada = `${dia}/${mes}/${año}`;
        return fechaFormateada;
    }

    useEffect(() => {
        filtrarPorPreCodificadas()
    }, [props.actoHistory])

    const formuStatusTemplate = (rowData: any) => {
        return (
            < p > {rowData && rowData.frmu_status == 0 ? 'Borrado' :
                rowData.frmu_status == 1 ? 'Vigente' :
                    rowData.frmu_status == 2 ? 'No Vigente' :
                        rowData.frmu_status == 3 ? 'No Vigente' :
                            ''
            }</p>
        );
    }

    const [checkedPreCodificadas, setCheckedPreCodificadas] = useState(false)

    const handleSelected = (data) => {
        if (data && data.length > 0) {
            let values
            if (props.frm.value) {
                values = props.frm.value.split(',')
            } else {
                values = props.frm.cmp_valores.split(',')
            }
            const newData = data.map((obj) => {
                const response = values.includes(obj.frmu_id)
                if (response) return obj
            });
            const arrayWithoutUndefined = newData.filter(element => element !== undefined);
            setSelectedProducts(arrayWithoutUndefined)
        }
    }

    function filtrarPorActClave(dataArray, clavesArray) {
        let claves = clavesArray

        if (typeof claves == 'string') {
            claves = clavesArray.split(',')
        }

        return dataArray.filter(item =>
            claves.some(clave =>
                item?.act_clave?.toLowerCase() == clave?.toLowerCase()
            )
        );

    }

    async function filtrarPorPreCodificadas() {
        //Consumir servicio getHistorialMultiple(frmu_id)
        let datosHistorial
        let datos = {
            frmu_id: props.information.frmu_id
        }
        const responseHistorial = await ApiServices.apiPost('getHistorialMultiple', datos)

        if (responseHistorial && responseHistorial.data && responseHistorial.data.addenda && responseHistorial.data.addenda.length > 0) {
            datosHistorial = responseHistorial.data.addenda
        }
        if (
            props && props.actoHistory && props.actoHistory.length > 0 && props && props.tramite && props?.tramite?.trm_status == 9 || props?.tramite?.trm_status == 10 ||
            props && props.actoHistory && props.actoHistory.length > 0 && props && props.information && props?.information?.trm_status == 9 || props?.information?.trm_status == 10
        ) {
            datosHistorial = props.actoHistory;
        }

        if (props && datosHistorial && datosHistorial.length > 0) {
            let filtrados = datosHistorial
            if (props && props.frm && props.frm.cmp_funcion) {
                filtrados = filtrarPorActClave(filtrados, props.frm.cmp_funcion);
            }
            if (props && props.tramite && props?.tramite?.trm_status == 9 || props?.tramite?.trm_status == 10) {
                const values = props && props.frm && props.frm.value && props.frm.value.length > 0 ? props.frm.value.split(',') : []
                const newData = values && values.length > 0 ? filtrados.filter(item => values.includes(item?.frmu_id?.trim())) : filtrados
                const newData2 = newData.filter(item => !item.act_clave.includes('CI'));
                handleSelected(newData2)
                setAllHistory(newData2)
            } else {
                const values = props && props.frm && props.frm.value && props.frm.value.length > 0 ? props.frm.value.split(',') : []
                const newData = values && values.length > 0 ? filtrados.filter(item => values.includes(item?.frmu_id?.trim())) : filtrados
                const newData2 = newData.filter(item => !item.act_clave.includes('CI'));
                handleSelected(newData2)
                setAllHistory(newData2)
            }
        }
    }

    const checkValidationSelected = async (values) => {
        let checker = true
        if (values && values.length > 0) {
            for (const element of values) {
                const datos = {
                    trm_id: props.tramite.trm_id,
                    frmu_id: element.frmu_id
                }
                await ApiServices.apiPost('validateTramite', datos).then(async res => {
                    if (res.data.errores && res.data.errores.length > 0) {
                        toast.current?.show({ severity: 'warn', summary: 'Acto Inválido', life: 3000 })
                        checker = false
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        }
        return checker
    }

    const [loadingCheck, setLoadingCheck] = useState(false)

    const handleSelect = async (value) => {
        setLoadingCheck(true)
        const validated = await checkValidationSelected(value)
        if (validated) {
            setLoadingCheck(false)
            if (value && value.length > 0) {
                const idsArray = value.map((singleElement) => singleElement.frmu_id)
                props.handleValueChange(idsArray, props.indexes[0], props.indexes[1])
            } else {
                props.handleValueChange([], props.indexes[0], props.indexes[1])
            }
            setSelectedProducts(value)
        } else {
            setLoadingCheck(false)
        }
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            filtrarTransacciones(searchField, convertirFecha(new Date(formattedDate)))
            setDate2(new Date(formattedDate));
        } else {
            setDate2(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };


    return (
        <section>
            <Panel header=" ">
                <Toast ref={toast} />
                <div className='grid'>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label">
                            {/* Input Done */}
                            <Calendar
                                value={date2}
                                onChange={(e: any) => {
                                    filtrarTransacciones(searchField, convertirFecha(e.value))
                                    setDate2(e.value)
                                }}
                                dateFormat="dd/MM/yy"
                                showIcon
                                className="p-inputtext-lg"
                                showButtonBar
                                locale="es"
                                onClearButtonClick={() => { filtrarTransacciones(searchField, convertirFecha(null)) }}
                                ref={calendarRef}
                                onInput={(e) => handleInputChange(e)}
                            />
                            <label htmlFor="username">Fecha</label>
                        </span>
                    </div>
                    <div className='col-12 md:col-6'>
                        <span className="p-float-label p-input-icon-left w-100">
                            <i className="pi pi-search" />
                            <InputText
                                className=' p-inputtext-lg'
                                type="search"
                                onChange={(e) => {
                                    filtrarTransacciones(e.target.value, convertirFecha(date2))
                                    setSearchField(e.target.value)
                                }}
                            />
                            <label htmlFor="username">Buscador general</label>
                        </span>
                    </div>
                </div>

                <div className="pt-3" style={{ height: '500px', overflow: 'hidden' }}>
                    <DataTable
                        value={allHistroy}
                        dataKey="frmu_id"
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No hay datos disponibles"
                        sortOrder={-1}
                        sortField="trm_consecutivo"
                        selectionMode="checkbox"
                        selection={selectedProducts}
                        onSelectionChange={(e) => handleSelect(e.value)}
                        scrollable
                        scrollHeight="450px" // Ajusta según tus necesidades
                    >
                        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
                        <Column field="trm_consecutivo" header="Numero de Control" sortable></Column>
                        <Column field="frmu_consecutivo" header="Consecutivo" sortable></Column>
                        <Column field='prop_folio' header="Folio" sortable></Column>
                        <Column field="trm_fecha" body={dateTemplate} header="Fecha de Ingreso" sortable></Column>
                        <Column field="act_clave" header="Acto" sortable></Column>
                        <Column field="act_nombre" header="Descripción" sortable ></Column>
                        <Column field="trm_escritura" header="Numero de Escritura" sortable ></Column>
                        <Column field="trm_firmado" header="Fecha de Inscripcion" sortable body={dateTemplate2}></Column>
                        <Column field="name" body={FirmadoTemplate} style={{ width: '14%' }}></Column>
                        <Column field="Estatus" body={formuStatusTemplate}></Column>
                        <Column field="price" body={EyeTemplate}></Column>
                    </DataTable>
                </div>
            </Panel>
        </section>
    );


}

export default React.memo(CancelarActo);