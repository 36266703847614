import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';
import { InputText } from 'primereact/inputtext';
import { Panel } from 'primereact/panel';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Timeline } from 'primereact/timeline';
import { Toast } from 'primereact/toast';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import configuration from '../../../configurationFile';
import DynamicForm from '../../../pages/actos/DynamicForm';
import ApiServices from '../../../service/apiServices';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import { Tooltip } from 'primereact/tooltip';
import { Tag } from 'primereact/tag';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';

const Historial = (props: any) => {
    /* TemporalData */

    function formatFecha(fecha: any) {
        if (fecha) {
            const fechaObj = new Date(fecha);
            fechaObj.setUTCHours(0, 0, 0, 0);
            const año = fechaObj.getUTCFullYear();
            const mes = (fechaObj.getUTCMonth() + 1).toString().padStart(2, '0');
            const dia = fechaObj.getUTCDate().toString().padStart(2, '0');
            return `${dia}/${mes}/${año}`;
        } else {
            return ''
        }
    }
    const isValidDateFormat = (day, month, year) => {
        const dayNum = parseInt(day, 10);
        const monthNum = parseInt(month, 10);
        const yearNum = parseInt(year, 10);

        if (isNaN(dayNum) || isNaN(monthNum) || isNaN(yearNum)) return false;
        if (dayNum < 1 || dayNum > 31) return false;
        if (monthNum < 1 || monthNum > 12) return false;
        if (yearNum < 1000) return false;

        const tempDate = new Date(`${yearNum}-${monthNum}-${dayNum}`);
        return tempDate instanceof Date && !isNaN(tempDate.getTime());
    };
    const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    };

    /*  */

    const user = authGuardService.getUser();

    const dataTableActos = useRef(null);
    const calendarioRef = useRef(null);
    const busquedaRef = useRef(null);
    const toast = useRef<any>(null);

    const [historial, setHistorial] = useState([]);
    const [historialClean, setHistorialClean] = useState([])
    const [historialBusquedaState, setHistorialBusquedaState] = useState([])
    const [historialCertificacionesState, setHistorialCertificacionesState] = useState([])
    const [historialOtrosState, setHistorialOtrosState] = useState([])

    const [date, setDate] = useState<Date | Date[] | undefined>()

    const [loadingPDF, setLoadingPDF] = useState(false)

    const [vigentesCheckBox, setVigentesCheckBox] = useState(false)
    const [certificadosCheckBox, setCertificadosCheckBox] = useState(false)
    const [prelacionCheckbox, setPrelacionCheckbox] = useState(false)
    const [loading, setLoading] = useState(false)
    const [loadingActo, setLoadingActo] = useState(false)

    const [actoInformation, setActoInformation] = useState()
    const [actoSeleccionado, setActoSeleccionado] = useState<any>('')
    const [modalActoActive, setModalActoActive] = useState<boolean>(false);
    const [informacionActo, setInformacionActo] = useState<any>()

    const cols = [
        { field: 'trm_consecutivo', header: 'Numero de Control' },
        { field: 'trm_fecha', header: 'Fecha de Ingreso' },
        { field: 'frmu_consecutivo', header: 'Consecutivo' },
        { field: 'act_clave', header: 'Acto' },
        { field: 'act_nombre', header: 'Descripción' },
        { field: 'trm_escritura', header: 'Numero de Escritura' },
        { field: 'trm_firmado', header: 'Fecha de Inscripcion' },
        { field: 'trm_status', header: 'Estatus Firmado' },
        { field: 'frmu_status', header: 'Estatus' },
        { field: 'price', header: '' }
    ];

    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));

    const transformDataForExport = () => {
        return historial.map((item) => ({
            ...item,
            trm_status: firmadoTemplateExport(item),
            frmu_status: statusTemplateExport(item)
        }));
    };

    const exportCSV = (selectionOnly: boolean) => {
        dataTableActos.current.exportCSV({ selectionOnly });
    };

    const exportPdf = () => {
        const transformedData = transformDataForExport();

        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc: any = new jsPDF.default('landscape', 'mm', 'a4');
                doc.autoTable(exportColumns, transformedData);
                doc.save('HistorialActos.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(historial);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'HistorialActos');
        });
    };

    const saveAsExcelFile = (buffer: BlobPart, fileName: string) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
            }
        });
    };

    const firmadoTemplateExport = (rowData: any) => {
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return `Firmado ${rowData.calificador ? ` - ${rowData.calificador}` : ''}`
        } else return 'Sin Firmar'
    }

    const statusTemplateExport = (data: any) => {
        return data && data.frmu_status == 0 ? 'Borrado' :
            data.frmu_status == 1 ? 'Vigente' :
                data.frmu_status == 2 ? 'No Vigente' :
                    data.frmu_status == 3 ? 'No Vigente' :
                        ''

    };

    const header = (
        <div className="flex align-items-center justify-content-end gap-2">
            <Button type="button" rounded icon="pi pi-file" onClick={() => exportCSV(false)} tooltip='Descargar CSV' tooltipOptions={{ position: 'top' }} />
            <Button type="button" rounded icon="pi pi-file-excel" onClick={exportExcel} tooltip='Descargar Excel' tooltipOptions={{ position: 'top' }} />
            <Button type="button" rounded icon="pi pi-file-pdf" onClick={exportPdf} tooltip='Descargar PDF' tooltipOptions={{ position: 'top' }} />
        </div>
    );

    const fechaTemplate = (data: any) => {
        return (
            <p>{data && formatFecha(data.trm_fecha)}</p>
        );
    }

    const fechaInscripcionTemplate = (data: any) => {
        return (
            <p>{data && formatFecha(data.trm_firmado)}</p>
        );
    }

    const firmadoTemplate = (rowData: any) => {
        if (rowData.trm_status == 9 || rowData.trm_status == 10) {
            return (
                <>
                    <Tooltip target=".custom-target-icon2" />
                    <Button
                        label={`Firmado ${rowData.calificador ? ` - ${rowData.calificador}` : ''}`}
                        text
                        severity="secondary"
                        tooltip={`${rowData.calificador ? `${rowData.calificador} - ${rowData.calificador_nombre}` : ''}`}
                        tooltipOptions={{ position: 'top' }}
                        /* onClick={() => {
                            ModalSetVisible(true)
                            setSelected(rowData)
                        }} */
                        style={{ fontSize: 16, width: '100%', display: 'flex', justifyContent: 'flex-start', textAlign: 'start' }}
                    />
                </>
            );
        } else {
            return (
                <p>Sin Firmar</p>
            )
        }
    }

    const statusTemplate = (rowData: any) => {
        const statusType = getStatusType(rowData.frmu_status)
        return (
            <>
                <Tooltip target=".custom-target-icon" />
                <Tag
                    className='custom-target-icon'
                    value={rowData.strStatus}
                    data-pr-tooltip={rowData.strCancelador}
                    data-pr-position="top"
                    severity={getSeverity(rowData.frmu_status)}
                    style={statusType == 'No Vigente' && { background: '#bababa' }}
                />
            </>
        )
    };

    const getStatusType = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'Borrador';
            case 1:
                return 'Vigente';
            case 2:
                return 'No Vigente';
            case 3:
                return 'No Vigente';
            default:
                return null;
        }
    };

    const getSeverity = (trm_status: any) => {
        switch (trm_status) {
            case 0:
                return 'danger'
            case 1:
                return 'success'
            case 2:
                return 'info';
            case 3:
                return 'info';
            default:
                return null;
        }
    };

    const opcionesTemplate = (data: any) => {
        return (
            <div className='text-center'>
                <Button
                    className="p-button-text"
                    icon="pi pi-eye"
                    onClick={() => { buscarActo(data) }}
                    loading={loading}
                />
                <Button
                    className="p-button-text"
                    icon="pi pi-file-pdf"
                    onClick={() => { savePDF(data.trm_id) }}
                    loading={loadingPDF}
                />
            </div>
        );
    }

    const handleHistorial = () => {
        setLoading(true)

        const resultadoOtros = [];
        const resultadoCertificaciones = [];

        const sliced = props.historial;

        setHistorialClean(sliced)

        sliced.forEach((item) => {
            const actClave = item.act_clave ? item.act_clave.toLowerCase() : '';
            if (!actClave.includes("ci")) {
                resultadoOtros.push(item);
            }
            if (actClave.includes("ci")) {
                resultadoCertificaciones.push(item);
            }
        });

        setHistorialCertificacionesState(resultadoCertificaciones);
        setHistorialOtrosState(resultadoOtros);
        setHistorial(resultadoOtros);
        setHistorialBusquedaState(resultadoOtros);
        setLoading(false);
    }

    const [selectedIds, setSelectedIds] = useState([])

    const filtrarHistorial = (vigentesIds = selectedIds, certificados = false, prelacion = false) => {
        let resultado;

        if (vigentesIds.length > 0 && !certificados) {
            resultado = historialOtrosState.filter(item =>
                vigentesIds.includes(String(item.frmu_status))
            );
        } else if (vigentesIds.length > 0 && certificados) {
            resultado = historialCertificacionesState.filter(item =>
                vigentesIds.includes(String(item.frmu_status))
            );
        } else if (vigentesIds.length === 0 && certificados) {
            resultado = historialCertificacionesState;
        } else {
            resultado = historialOtrosState;
        }

        if (prelacion && vigentesIds.length > 0) {
            resultado = resultado.filter(item =>
                vigentesIds.includes(String(item.frmu_status)) &&
                !(item.trm_status === 9 || item.trm_status === 10)
            );
        } else if (prelacion && vigentesIds.length <= 0) {
            resultado = resultado.filter(item =>
                ['1'].includes(String(item.frmu_status)) &&
                !(item.trm_status === 9 || item.trm_status === 10)
            );
        }

        setHistorial(resultado);
        setHistorialBusquedaState(resultado);
    };

    const filtrarVigentes = (vigentesIds = selectedIds,) => {
        setLoading(true);
        filtrarHistorial(vigentesIds, certificadosCheckBox, prelacionCheckbox);
        setLoading(false);
    };

    const filtrarCertificados = () => {
        if (certificadosCheckBox) {
            setCertificadosCheckBox(false)
            filtrarHistorial(selectedIds, false, prelacionCheckbox);
        } else {
            setLoading(true)
            setCertificadosCheckBox(true)
            filtrarHistorial(selectedIds, true, prelacionCheckbox);
            setLoading(false)
        }
    }

    function transformarEstados(estados) {
        return estados.map((estado) => String(estado.code));
    }

    const filtrarPrelacion = () => {
        const codigos = transformarEstados(selectedIds);
        if (prelacionCheckbox) {
            setPrelacionCheckbox(false)
            filtrarHistorial(codigos, certificadosCheckBox, false);
        } else {
            setLoading(true)
            setPrelacionCheckbox(true)
            filtrarHistorial(codigos, certificadosCheckBox, true);
            setLoading(false)
        }
    };

    const filtrarFecha = (data) => {
        if (data) {
            setDate(data)
            const fecha = new Date(data).toISOString().split('T')[0];
            const resultado = historial.filter(item => item.trm_fecha === fecha);
            setHistorial(resultado)
            setHistorialBusquedaState(resultado)
        } else {
            filtrarHistorial(selectedIds, certificadosCheckBox, prelacionCheckbox)
        }
    }

    const handleDateTyping = (e) => {
        const value = e.target.value;

        let formattedDate = null;
        if (value.length === 8) {
            const day = value.substring(0, 2);
            const month = value.substring(2, 4);
            const year = value.substring(4, 8);

            if (isValidDateFormat(day, month, year)) formattedDate = `${month}/${day}/${year}`
            else {
                console.log('Fecha inválida ingresada');
                return;
            }
        }

        if (formattedDate) {
            const parsedDate = new Date(formattedDate);
            setDate(parsedDate);
            filtrarFecha(parsedDate);
        }
    };

    const handleBusqueda = useCallback((query) => {
        const lowerSearchText = removeAccents(query.toLowerCase());

        const newArray = historialBusquedaState
            .filter(item => {
                return Object.values(item).some(value => {
                    if (typeof value === 'string') {
                        const normalizedValue = removeAccents(value.toLowerCase());
                        return normalizedValue.includes(lowerSearchText);
                    }
                    return false;
                });
            });

        setHistorial(newArray);
    }, [historialBusquedaState]);


    const handleEnter = (event) => {
        if (event.key === 'Enter') {
            const value = busquedaRef.current.value;
            handleBusqueda(value);
        }
    };

    const timelineItemTemplate = (item: any) => {
        return (
            <div className=''>
                <div className='grid'>
                    <div className='col-12'>
                        <Panel headerTemplate={
                            <div className='p-panel-header block'>
                                <div className='grid'>
                                    <div className='col-3'>
                                        <p>
                                            <span className='font-bold'>Fecha de registro: </span>
                                            {formatFecha(item.trm_fecha)}
                                        </p>
                                    </div>
                                    <div className='col-3'>
                                        <p>
                                            <span className='font-bold'>Numero de Escritura:</span>
                                            {item && item.trm_escritura ? ` ${item.trm_escritura}` : ''}
                                        </p>
                                    </div>
                                    {
                                        item && item.history && item.history.length > 0 && item.history[0].trm_id_padre &&
                                        <div className='col-3'><span className='font-bold'><p>Multiples Folios</p></span></div>
                                    }

                                </div>
                            </div>
                        }>
                            <DataTable
                                value={item.history}
                                dataKey="id"
                                paginator
                                rows={5}
                                className="datatable-responsive"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                emptyMessage="No products found."
                            >
                                <Column field='trm_consecutivo' header="Numero de Control" sortable></Column>
                                <Column field='trm_fecha' header="Fecha" sortable body={fechaTemplate}></Column>
                                <Column field="act_clave" header="Clave" sortable ></Column>
                                <Column field="act_nombre" header="Acto" sortable ></Column>
                                <Column field="name" body={firmadoTemplate} ></Column>
                                <Column field="frmu_status" body={statusTemplate} ></Column>
                                <Column field="price" style={{ width: '10rem' }} body={opcionesTemplate} ></Column>
                            </DataTable>
                        </Panel>
                    </div>
                </div>

            </div>
        );
    };

    const savePDF = async (trm: any) => {
        setLoadingPDF(true)
        let datos
        datos = { "code": 701, area_id: user.area_id, trm_id: trm }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                if (res.data.url[0].valido == 0) {
                    setLoadingPDF(false)
                    toast.current?.show({
                        severity: 'error',
                        summary: 'PDF No Disponible',
                        detail: res.data.url[0].mensaje,
                        life: 3000
                    });
                } else {
                    setLoadingPDF(false)
                    if (typeof res.data.url === 'object') {
                        if (res.data.url && res.data.url.length > 0) {
                            res.data.url.forEach((single: any) => {
                                window.open(`${configuration.url}${single.url}`, '_blank');
                            });
                        }
                    } else {
                        window.open(`${configuration.url}${res.data.url}`, '_blank')
                    }
                }
            }
        }).catch(error => {
            setLoadingPDF(false)
            toast.current?.show({
                severity: 'error',
                summary: 'Error',
                detail: 'Hubo un error en el servidor, intente de nuevo mas tarde',
                life: 3000
            });
        })
    }

    const [detailTramite, setDetailTramite] = useState<any>()

    const buscarActo = (tramite: any) => {
        setLoadingActo(true)

        let datos = { ptrm_id: 0, trm_id: tramite.trm_id }

        let datos2 = { code: 204, trm_id: tramite.trm_id }
        datos2 = EncryptService.encryptData(JSON.stringify(datos2));
        ApiServices.processRequestAdd(datos2).then(async res => {
            if (res.data.valido === 1) {
                setDetailTramite(res.data.addenda)
                ApiServices.apiPost('getFormatosResp', datos).then(async res => {
                    if (res.data.valido === 1 && res.data.addenda.length > 0) {
                        const found = res.data.addenda.find((objeto: any) => objeto.act_id === tramite.act_id);
                        if (found && found.frmu_id) {
                            let datos = { act_id: tramite.act_id }
                            ApiServices.apiPost('getCampos', datos).then(async res => {
                                setActoInformation(res.data.addenda)
                                let merged = { ...tramite };
                                merged = { ...merged, ...found };
                                setActoSeleccionado(merged)
                                setModalActoActive(true)
                                setLoadingActo(false)
                            }).catch(error => {
                                console.log(error)
                            })
                        }
                    } else {
                        setLoadingActo(false)
                        toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
                    }
                }).catch(error => {
                    setLoadingActo(false)
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
                })
            }
        }).catch(error => {
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })


    }

    useEffect(() => {
        if (props.historial && props.historial.length > 0)
            handleHistorial()
    }, [props.historial])

    const obtenerCodes = (array) => array.map(item => item.code);

    return (
        <section>
            <Toast ref={toast} />

            <div className='col-12 md:col-12' style={{ marginTop: 20 }}>
                <h3 className='color-violet'>Historial de actos</h3>
            </div>

            <Divider type="dashed" />

            <div className='grid' style={{ alignItems: 'center' }}>
                <div className='col-12 md:col-2'>
                    <MultiSelect value={selectedIds} onChange={(e) => {
                        setSelectedIds(e.value)
                        const codes = obtenerCodes(e.value);
                        filtrarVigentes(codes)
                    }} options={[
                        { name: 'Vigente', code: '1' },
                        { name: 'Cancelado', code: '3' },
                        { name: 'Expiro el Plazo', code: '4' }
                    ]} optionLabel="name"
                        placeholder="Filtrar por..." maxSelectedLabels={3} />
                </div>
                <div className='col-12 md:col-3'>
                    <Checkbox
                        inputId="certificados"
                        checked={certificadosCheckBox}
                        onChange={filtrarCertificados}
                    />
                    <label htmlFor="certificados" className="ml-2">Mostrar Certificados</label>
                </div>
                <div className='col-12 md:col-2'>
                    <Checkbox
                        inputId="prelacion"
                        checked={prelacionCheckbox}
                        onChange={filtrarPrelacion}
                    />
                    <label htmlFor="prelacion" className="ml-2">Prelación</label>
                </div>
                <div className='col-12 md:col-2'>
                    <span className="p-float-label">
                        <Calendar
                            value={date}
                            onChange={(e) => { filtrarFecha(e.value) }}
                            dateFormat="dd/MM/yy"
                            showIcon
                            className="p-inputtext-lg"
                            showButtonBar
                            locale="es"
                            ref={calendarioRef}
                            onInput={handleDateTyping}
                        />
                        <label htmlFor="username">Fecha de Ingreso</label>
                    </span>
                </div>
                <div className='col-12 md:col-3'>
                    <span className="p-float-label p-input-icon-left w-100">
                        <InputText
                            ref={busquedaRef}
                            className='p-inputtext-lg'
                            onKeyPress={handleEnter}
                        />
                        <label htmlFor="username">Buscador General</label>
                    </span>
                </div>
            </div>

            <Divider type="dashed" />

            <div className='pt-3' style={{ alignItems: 'center', flexDirection: 'column' }}>
                {loading ?
                    <ProgressSpinner
                        className="custom-spinner"
                        style={{ width: '50px', height: '50px', stroke: '#000000' }}
                        strokeWidth="8"
                        animationDuration=".5s"
                    />
                    :
                    <DataTable
                        ref={dataTableActos}
                        value={historial}
                        dataKey="id"
                        paginator
                        rows={10}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        className="datatable-responsive"
                        currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} actos"
                        emptyMessage="No se encontraron actos."
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        removableSort
                    >
                        <Column field='trm_consecutivo' header="Numero de Control" sortable></Column>
                        <Column field='trm_fecha' header="Fecha de Ingreso" body={fechaTemplate} sortable></Column>
                        <Column field="frmu_consecutivo" header="Consecutivo" sortable ></Column>
                        <Column field="act_clave" header="Acto" sortable ></Column>
                        <Column field="act_nombre" header="Descripción" sortable ></Column>
                        <Column field="trm_escritura" header="Numero de Escritura" sortable ></Column>
                        <Column field="trm_firmado" header="Fecha de Inscripcion" body={fechaInscripcionTemplate} sortable></Column>
                        <Column field="trm_status" header="Estatus Firmado" body={firmadoTemplate} ></Column>
                        <Column field="frmu_status" header="Estatus" body={statusTemplate} ></Column>
                        <Column field="price" header={header} style={{ width: '10rem' }} body={opcionesTemplate} ></Column>
                    </DataTable>
                }
            </div>

            {/* Modal Ver Acto */}
            <Dialog
                header={`${(actoSeleccionado && actoSeleccionado.act_nombre) || ''}`}
                maximizable
                visible={modalActoActive}
                style={{ width: '50%' }}
                onHide={() => setModalActoActive(false)}
            >
                <DynamicForm
                    edicion={false}
                    preTramiteFolio={props.detallePreTramite}
                    propiedadDetail={props.detallePropiedad}
                    informationForm={actoInformation}
                    information={actoSeleccionado}
                    actoHistory={historialClean}
                    actoHistoryClone={historialClean}
                    setDynamicInformationParent={setInformacionActo}

                    tramiteDetail={detailTramite}
                    tramite={detailTramite?.tramite}
                />
            </Dialog>
        </section>
    );

}

export default React.memo(Historial);