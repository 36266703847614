/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/apiServices';
import I1Acto from '../actos/I1'
import I12Acto from '../actos/I12'
import { Checkbox } from 'primereact/checkbox';
import { InputNumber } from 'primereact/inputnumber';
import authGuardService from '../../service/authGuardService';
import CancelarActo from './CancelarActo';
import NuevoFolioTitulares from './NuevoFolioTitulares';
import FolioDinamico from './FolioDinamico';
import FolioAutomaticData from './FolioAutomaticData';

/*
L -> Label
T -> Text
C -> Combo - Dropdown
D -> Date
B -> Checkbox

R -> Radio
TA -> TextArea
M -> Text with Money Conversion
*/

const DynamicForm = (props: any) => {
    const user = authGuardService.getUser()

    const [notaryList, setNotaryList] = useState([])
    const [estados, setEstados] = useState([])
    const [municipios, setMunicipios] = useState([])
    const [municipiosSingle, setMunicipiosSingle] = useState([])
    const [selectedNotaryList, setSelectedNotaryList] = useState<any>(null)
    const [selectedNotaryState, setSelectedNotaryState] = useState<any>(null)
    const [selectedNotaryMun, setSelectedNotaryMun] = useState<any>(null)

    const [information, setInformation] = useState(props.information)
    const [informationForm, setInformationForm] = useState<any>([])
    const [informationFormTitu, setInformationFormTitu] = useState<any>([])

    const [titulares, setTitulares] = useState([])
    const [titularesInput, setTitularesInput] = useState('')

    const [date, setDate] = useState(null);

    const [titularesCollection, setTitularesCollection] = useState([])
    const [titularesPool, setTitularesPool] = useState([])

    function generateUUID() {
        // Genera un UUID v4 en el formato XXXXXXXX-XXXX-4XXX-YXXX-XXXXXXXXXXXX
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (char) {
            const random = Math.random() * 16 | 0; // Número aleatorio entre 0 y 15
            const value = char === 'x' ? random : (random & 0x3 | 0x8); // Asegura que Y sea 8, 9, A o B
            return value.toString(16); // Convierte el número a hexadecimal
        });
    }

    const addToCollection = (titular, indexes) => {
        const newTitular = JSON.parse(JSON.stringify(titular))
        newTitular.ptit_id = generateUUID()
        setTitularesCollection([...titularesCollection, newTitular]);
        handleValueChange([...titularesCollection, newTitular], indexes[0], indexes[1]);
    }

    const handleTitularesCollect = (titulares) => {
        const indices: any = [];
        informationForm.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_tipo === 'DT4') {
                    indices.push(index, subIndex);
                }
            });
        });

        const newTitulares = []
        const newTitularesPool = []

        if (titulares && titulares.length > 0)
            for (let i = 0; i < titulares.length; i++) {
                const exists = titularesCollection.some(titular => titular === titulares[i]);
                const existsPool = titularesCollection.some(titular => titular.ptit_id === titulares[i].ptit_id);
                if (!exists) {
                    titulares[i].ptit_id = generateUUID()
                    newTitulares.push(titulares[i])
                }
                if (!existsPool) {
                    newTitularesPool.push(titulares[i])
                }
            }
        setTitularesPool([...titularesPool, ...newTitularesPool]);
        setTitularesCollection([...titularesCollection, ...newTitulares]);
        if (indices && indices.length > 0)
            handleValueChange([...titularesCollection, ...newTitulares], indices[0], indices[1]);
    }

    function formatValues(arr: any) {
        let groupedArray = arr.map((singleItem: any) => {
            const cloneSingleItem = JSON.parse(JSON.stringify(singleItem))
            if (cloneSingleItem.cmp_tipo === 'C') {
                const newItems = cloneSingleItem.cmp_valores.split("|||");
                cloneSingleItem.cmpValores = newItems
            }
            return cloneSingleItem
        }, []);

        if (information.frmu_id) {
            let datos = {
                frmu_id: information.frmu_id
            }
            ApiServices.apiPost('getRespuestas', datos).then(async res => {
                const previosValuesFormatted: any = []
                const response = res.data.addenda
                if (response && response.length > 0) {
                    groupedArray.map((singleGrouped: any) => {
                        let elementNew = JSON.parse(JSON.stringify(singleGrouped))
                        response.map((singleValue: any) => {
                            if (elementNew.cmp_id === singleValue.cmp_id) {
                                if (elementNew.cmp_titulo == "Estado") {
                                    if (singleValue.cmpu_valor) {
                                        if (typeof singleValue.cmpu_valor == 'string' && singleValue.cmpu_valor[0] != `"`) {
                                            setSelectedNotaryState(singleValue.cmpu_valor)
                                            elementNew.value = singleValue.cmpu_valor
                                        } else {
                                            setSelectedNotaryState(JSON.parse(singleValue.cmpu_valor))
                                            elementNew.value = JSON.parse(singleValue.cmpu_valor)
                                        }
                                        getMunicipios(singleValue.cmpu_valor)
                                    }
                                } else if (elementNew.cmp_titulo == "Municipio") {
                                    if (singleValue.cmpu_valor) {
                                        if (typeof singleValue.cmpu_valor == 'string' && singleValue.cmpu_valor[0] != `"`) {
                                            setSelectedNotaryMun(singleValue.cmpu_valor)
                                            elementNew.value = singleValue.cmpu_valor
                                        } else {
                                            setSelectedNotaryMun(JSON.parse(singleValue.cmpu_valor))
                                            elementNew.value = JSON.parse(singleValue.cmpu_valor)
                                        }
                                    }
                                } else if (elementNew.cmp_funcion_campo == "auto_fedatario") {
                                    if (singleValue.cmpu_valor) {
                                        const preSelected = searchNotaryReturn(notaryList, singleValue.cmpu_valor)
                                        if (preSelected)
                                            elementNew.value = preSelected
                                        else
                                            elementNew.value = JSON.parse(singleValue.cmpu_valor)
                                    }
                                } else {
                                    if (singleValue.cmpu_valor) {
                                        if (typeof singleValue.cmpu_valor == 'string' && singleValue.cmpu_valor[0] != `"`)
                                            elementNew.value = singleValue.cmpu_valor
                                        else if (typeof singleValue.cmpu_valor == 'string' && singleValue.cmpu_valor[0] == `"`)
                                            elementNew.value = singleValue.cmpu_valor
                                        else
                                            elementNew.value = JSON.parse(singleValue.cmpu_valor)
                                    }
                                }
                            }
                        })
                        previosValuesFormatted.push(elementNew)
                    })
                    groupByLinea(previosValuesFormatted)
                } else {
                    groupByLinea(groupedArray);
                }
            }).catch(error => {
                console.log(error)
            })
        } else {
            groupByLinea(groupedArray);
        }
    }

    function groupByLinea(arr: any) {
        const groupedArray = arr.reduce((acc: any, item: any) => {
            const linea = item.cmp_linea;
            const existingGroup = acc.find((group: any) => group[0].cmp_linea === linea);

            if (existingGroup) {
                existingGroup.push(item);
            } else {
                acc.push([item]);
            }

            return acc;
        }, []);
        props.setDynamicInformationParent(groupedArray)
        agregarClasesAlArray(groupedArray);
    }

    function agregarClasesAlArray(array: any) {
        const anchoClases: any = {
            1: 3,
            2: 6,
            3: 9,
            4: 12
        };

        const newArray = array.map((element: any) => {
            return element.map((obj: any) => {
                const cmpAncho = obj.cmp_ancho;
                if (anchoClases.hasOwnProperty(cmpAncho)) {
                    return { ...obj, class: `${anchoClases[cmpAncho]}` };
                }
                return obj;
            });
        });
        classClassesOfArray(newArray)
    }

    function classClassesOfArray(array: any) {
        const newArray = array.map((element: any) => {
            const arrayLength = element.length;
            if (arrayLength === 1) {
                const elementClass = element[0].class;
                const newToAdd = 12 - elementClass;
                if (newToAdd > 0) {
                    element.push({
                        cmp_tipo: "vacio",
                        class: `${newToAdd}`,
                    });
                }
                return element;
            } else if (arrayLength === 2 || arrayLength === 3) {
                const elementClass = element.map((singleElement: any) => {
                    return singleElement.class;
                });
                const sum = elementClass.reduce((total: any, num: any) => total + parseInt(num), 0);
                const newToAdd = 12 - sum;
                if (newToAdd > 0) {
                    element.push({
                        cmp_tipo: "vacio",
                        class: `${newToAdd}`,
                    });
                }
                return element;
            } else {
                return element
            }
        });
        setInformationForm(newArray);
        setInformationFormTitu(newArray)

        const cloneInformationForm = JSON.parse(JSON.stringify(newArray))
        const indexes = encontrarIndices(cloneInformationForm)
        if (indexes && indexes.length > 0) {
            const not = cloneInformationForm[indexes[0]][indexes[1]].value
            if (!not)
                searchNotary(notaryList, cloneInformationForm)
        }
    }

    const handleValueChange = (value: any, index: any, index2: any) => {
        const cloneInformationForm = JSON.parse(JSON.stringify(informationForm))
        cloneInformationForm[index][index2].value = value
        props.setDynamicInformationParent(cloneInformationForm)
        setInformationForm(cloneInformationForm)
    }

    const handleDeleteTitularString = async (valueToDelete) => {
        const cloneInformationForm = JSON.parse(JSON.stringify(informationForm))
        const indices = encontrarIndicesTitulares(cloneInformationForm)

        if (props.titulares && props.titulares.length > 0) {
            let stringTitulares = ""
            const newTitulares = props.titulares.map((item: any) => {
                stringTitulares = stringTitulares + `${item.ptit_nom || ''} ${item.ptit_pat || ''} ${item.ptit_mat || ''}` + ', '
                return {
                    name: `${item.ptit_nom || ''} ${item.ptit_pat || ''} ${item.ptit_mat || ''}`,
                    code: item.ptit_id
                }
            });

            const titularesEdit = stringTitulares

            if (indices && indices.length > 0) {
                const stringToEdit = titularesEdit
                const arrayEdit = stringToEdit.split(',')
                const editedArray = arrayEdit.filter((individualTitular) => {
                    const value1 = individualTitular.replaceAll(' ', '');
                    const value2 = valueToDelete.replaceAll(' ', '');

                    return value1.toUpperCase() == value2.toUpperCase()
                })

                const finalString = editedArray.toString()

                const exists = cloneInformationForm[indices[0]][indices[1]].value ? cloneInformationForm[indices[0]][indices[1]].value.includes(finalString) : false

                if (finalString && finalString.length > 0 && indices && !exists) {
                    await new Promise(resolve => setTimeout(resolve, 1000));

                    handleValueChange(`${cloneInformationForm[indices[0]][indices[1]].value ? `${cloneInformationForm[indices[0]][indices[1]].value},` || '' : ''} ${finalString}`, indices[0], indices[1])
                }
            }
        }


    }

    useEffect(() => {
        if (informationFormTitu && titularesInput) {
            const cloneInformationForm = JSON.parse(JSON.stringify(informationFormTitu))
            const indices = encontrarIndicesTitulares(cloneInformationForm)

            if (titularesInput && titularesInput.length > 0 && indices) {
                if (indices && indices.length > 1) {
                    if (!(informationFormTitu[indices[0]][indices[1]].value && informationFormTitu[indices[0]][indices[1]].value.length > 0))
                        handleValueChange(titularesInput, indices[0], indices[1])
                }
            }
        }
    }, [informationFormTitu, titularesInput])

    useEffect(() => {
        if (props.informationForm && notaryList && notaryList.length > 0 && estados && estados.length > 0) {
            formatValues(props.informationForm)
        }
    }, [props.informationForm, notaryList, estados])

    useEffect(() => {
        formatTitulares()
        handleTitularesCollect(props.titulares)
    }, [props.titulares])

    useEffect(() => {
        getNotaryList()
        getEstados()
    }, [])

    const getEstados = async () => {
        let datos
        ApiServices.apiPost('getEstado', datos).then(async res => {
            setEstados(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getMunicipios = async (edo: any) => {
        const datos = {
            cp_edo: edo
        }
        ApiServices.apiPost('getMunicipio', datos).then(async res => {
            setMunicipios(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNotaryList = async () => {
        let datos
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            setNotaryList(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const searchNotary = (notarys: any, clone: any) => {
        if (notarys && notarys.length > 0) {
            notarys.map((element: any) => {
                if (element.not_id === user.not_id) {
                    setSelectedNotaryList(element)
                    setAutoLic(element, clone)
                    setSelectedNotaryState(element.cp_edo)
                    setSelectedNotaryMun(element.cp_mun)
                } else {
                    if (props &&
                        props.tramite &&
                        props.tramite.not_id &&
                        element.not_id == props.tramite.not_id) {
                        setSelectedNotaryList(element)
                        setAutoLic(element, clone)
                        setSelectedNotaryState(element.cp_edo)
                        setSelectedNotaryMun(element.cp_mun)
                    }
                }
            })
        }
    }

    const searchNotaryReturn = (notarys: any, not_number: any) => {
        if (notarys && notarys.length > 0) {
            return notarys.find((element: any) => element.not_id == not_number);
        }
    }

    useEffect(() => {
        props.setDynamicInformationParent(informationForm)
        formatTitulares(informationForm)
    }, [informationForm])

    const formatCurrency = (amount: any): any => {
        return amount.toLocaleString('en-US', {
            style: 'currency',
            currency: 'MXN',
            minimumFractionDigits: 2,
        });
    };

    const formatTitulares = (form = informationForm) => {
        if (props.titulares && props.titulares.length > 0) {
            let stringTitulares = ""
            const newTitulares = props.titulares.map((item: any) => {
                stringTitulares = stringTitulares + `${item.ptit_nom || ''} ${item.ptit_pat || ''} ${item.ptit_mat || ''}` + ', '
                return {
                    name: `${item.ptit_nom || ''} ${item.ptit_pat || ''} ${item.ptit_mat || ''}`,
                    code: item.ptit_id
                }
            });

            setTitularesInput(stringTitulares)
            setTitulares(newTitulares)
        }
    }

    const notarioTemplate = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option ? `${option.not_fedata || ''} - ${option.not_nom || ''} ${option.not_pat || ''} ${option.not_mat || ''}` : ''}</div>
            </div>
        );
    };

    const notarioTemplateNumber = (option: any) => {
        return (
            <div className="flex align-items-center">
                <div>{option ? `Notaria - ${option.not_fedata || ''}` : ''}</div>
            </div>
        );
    };

    function encontrarIndicesNotario(array: any) {
        const indices: any = [];
        array.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_funcion_campo === 'auto_fedatario' && obj.cmp_titulo == "Notario N°") {
                    indices.push(index, subIndex);
                }
            });
        });
        return indices;
    }

    function encontrarIndices(array: any) {
        const indices: any = [];
        array.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_funcion_campo === 'auto_lic') {
                    indices.push(index, subIndex);
                }
            });
        });
        return indices;
    }

    function encontrarIndicesTitulares(array: any) {
        const indices: any = [];
        array.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_funcion === 'titulares_concat') {
                    indices.push(index, subIndex);
                }
            });
        });
        return indices;
    }

    function encontrarIndicesEstado(array: any) {
        const indices: any = [];
        array.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_funcion_campo === 'auto_fedatario' && obj.cmp_titulo == "Estado") {
                    indices.push(index, subIndex);
                }
            });
        });
        return indices;
    }

    function encontrarIndicesMunicipio(array: any) {
        const indices: any = [];
        array.forEach((subArray: any, index: any) => {
            subArray.forEach((obj: any, subIndex: any) => {
                if (obj.cmp_funcion_campo === 'auto_fedatario' && obj.cmp_titulo == "Municipio") {
                    indices.push(index, subIndex);
                }
            });
        });
        return indices;
    }

    const searchAutLic = (value: any, index1: any, index2: any) => {
        const cloneInformationForm = JSON.parse(JSON.stringify(informationForm))

        const indexes = encontrarIndices(cloneInformationForm)
        const indexesNotario = encontrarIndicesNotario(cloneInformationForm)
        const indexesEstado = encontrarIndicesEstado(cloneInformationForm)
        const indexesMunicipio = encontrarIndicesMunicipio(cloneInformationForm)

        if (indexes) {
            cloneInformationForm[index1][index2].value = value
            cloneInformationForm[indexes[0]][indexes[1]].value = value.nombre_notario
            cloneInformationForm[indexesNotario[0]][indexesNotario[1]].value = value
            cloneInformationForm[indexesEstado[0]][indexesEstado[1]].value = value.cp_edo
            cloneInformationForm[indexesMunicipio[0]][indexesMunicipio[1]].value = value.cp_mun

            props.setDynamicInformationParent(cloneInformationForm)
            setInformationForm(cloneInformationForm)
            setSelectedNotaryState(value.cp_edo)
            setSelectedNotaryMun(value.cp_mun)
        }
    }

    const setAutoLic = (value: any, clone: any) => {
        let compare = (informationForm && informationForm.length > 0) ? informationForm : clone
        const cloneInformationForm = JSON.parse(JSON.stringify(compare))
        const indexes = encontrarIndices(cloneInformationForm)
        const indexesNotario = encontrarIndicesNotario(cloneInformationForm)
        const indexesEstado = encontrarIndicesEstado(cloneInformationForm)
        const indexesMunicipio = encontrarIndicesMunicipio(cloneInformationForm)

        if (indexes && indexes.length > 0) {
            cloneInformationForm[indexes[0]][indexes[1]].value = value.nombre_notario
            cloneInformationForm[indexesNotario[0]][indexesNotario[1]].value = value
            cloneInformationForm[indexesEstado[0]][indexesEstado[1]].value = value.cp_edo
            cloneInformationForm[indexesMunicipio[0]][indexesMunicipio[1]].value = value.cp_mun

            props.setDynamicInformationParent(cloneInformationForm)
            setInformationForm(cloneInformationForm)
            setSelectedNotaryState(value.cp_edo)
            setSelectedNotaryMun(value.cp_mun)
        }
    }

    const findPadre = (idPadre: any, padrevalor) => {
        if (informationForm && informationForm.length > 0) {
            const temporal = informationForm.flat()
            const foundObject = temporal.find(item => item.cmp_id === idPadre);

            if (foundObject) {
                if (foundObject.value == padrevalor || foundObject.valor == padrevalor) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        }
    }

    const calendarRef = useRef(null);

    const handleInputChange = (e, update, index, index2) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            update(new Date(formattedDate), index, index2);
        } else {
            update(null, index, index2);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };
    const setEscritura = (type, index, index2) => {
        handleValueChange(type, index, index2)
        return type
    }

    return (
        <div className='grid'>
            <div className='col-12 md:col-12'>
                <h3>{information ? information.frm_desc : ''}</h3>
            </div>
            {information && information.ta_antecedente &&
                <div className='col-12 md:col-12'>
                    <p>Antecedentes: {information.ta_antecedente}</p>
                </div>
            }
            {
                informationForm && informationForm.map((individualGroup: any, index: any) => (
                    individualGroup.map((individualElement: any, index2: any) => {
                        if (individualElement.cmp_tipo === 'L') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`}>
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_valores : ''}</label>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`}>
                                        <label htmlFor="username">{individualElement ? individualElement.cmp_valores : ''}</label>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_funcion === 'titulares') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Dropdown
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                    value={individualElement.value}
                                                    options={titulares}
                                                    placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                    optionLabel="name"
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Dropdown
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                value={individualElement.value}
                                                options={titulares}
                                                placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                optionLabel="name"
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_funcion === 'auto' && individualElement.cmp_funcion_campo == "auto_lic" && individualElement.cmp_titulo == 'Licenciado') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="licenciado"
                                                    value={individualElement.value}
                                                    className="p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                                <label htmlFor="licenciado">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={individualElement.value}
                                                className="p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_titulo === 'Estado' && individualElement.cmp_funcion == 'auto' && individualElement.cmp_funcion_campo == 'auto_fedatario') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="username"
                                                    value={selectedNotaryState}
                                                    className="p-inputtext-lg"
                                                    disabled />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={selectedNotaryState}
                                                className="p-inputtext-lg"
                                                disabled />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_titulo === 'Municipio' && individualElement.cmp_funcion == 'auto' && individualElement.cmp_funcion_campo == 'auto_fedatario') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="username"
                                                    value={selectedNotaryMun}
                                                    className="p-inputtext-lg"
                                                    disabled />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={selectedNotaryMun}
                                                className="p-inputtext-lg"
                                                disabled />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_titulo === 'Estado') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Dropdown
                                                    value={
                                                        typeof individualElement.value == 'object' ?
                                                            individualElement.value :
                                                            { cp_edo: individualElement.value }
                                                    }
                                                    options={estados}
                                                    placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                    onChange={(e) => {
                                                        getMunicipios(e.value.cp_edo)
                                                        handleValueChange(e.value, index, index2)
                                                    }}
                                                    optionLabel="cp_edo"
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Dropdown
                                                value={
                                                    typeof individualElement.value == 'object' ?
                                                        individualElement.value :
                                                        { cp_edo: individualElement.value }
                                                }
                                                options={estados}
                                                placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                onChange={(e) => {
                                                    getMunicipios(e.value.cp_edo)
                                                    handleValueChange(e.value, index, index2)
                                                }}
                                                optionLabel="cp_edo"
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_titulo === 'Municipio') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Dropdown
                                                    value={
                                                        typeof individualElement.value == 'object' ?
                                                            individualElement.value :
                                                            { cp_mun: individualElement.value }
                                                    }
                                                    options={municipios}
                                                    placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                    onChange={(e) => {
                                                        handleValueChange(e.value, index, index2)
                                                    }}
                                                    optionLabel="cp_mun"
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Dropdown
                                                value={
                                                    typeof individualElement.value == 'object' ?
                                                        individualElement.value :
                                                        { cp_mun: individualElement.value }
                                                }
                                                options={municipios}
                                                placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                onChange={(e) => {
                                                    handleValueChange(e.value, index, index2)
                                                }}
                                                optionLabel="cp_mun"
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo == 'T' && individualElement.cmp_funcion === 'auto' && individualElement.cmp_funcion_campo == "auto_fedatario") {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Dropdown
                                                    value={individualElement.value || selectedNotaryList}
                                                    options={notaryList}
                                                    placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                    onChange={(e) => {
                                                        searchAutLic(e.value, index, index2)
                                                    }}
                                                    optionLabel="nombre_notario"
                                                    itemTemplate={notarioTemplate}
                                                    valueTemplate={notarioTemplateNumber}
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Dropdown
                                                value={individualElement.value || selectedNotaryList}
                                                options={notaryList}
                                                placeholder="Seleccionar" className="w-full p-inputtext-lg"
                                                onChange={(e) => {
                                                    searchAutLic(e.value, index, index2)
                                                }}
                                                optionLabel="nombre_notario"
                                                itemTemplate={notarioTemplate}
                                                valueTemplate={notarioTemplateNumber}
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'T' && individualElement.cmp_funcion == 'titulares_concat') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="username"
                                                    value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : titularesInput}
                                                    className="p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                } else {
                                    return <></>
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : titularesInput}
                                                className="p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'T' && individualElement.cmp_funcion == 'auto' && individualElement.cmp_funcion_campo == 'escritura') {
                            const type1 = props?.preTramiteDetail?.ptrm_escritura
                            const type2 = props?.preTramiteFolio?.ptrm_escritura
                            const type3 = props?.tramite?.trm_escritura
                            const type4 = props?.information?.trm_escritura

                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="username"
                                                    value={(
                                                        individualElement && individualElement.value && individualElement.value !== 'undefined') ?
                                                        individualElement.value :
                                                        individualElement.value == '' ? individualElement.value :
                                                            type1 ? setEscritura(type1, index, index2) :
                                                                type2 ? setEscritura(type2, index, index2) :
                                                                    type3 ? setEscritura(type3, index, index2) :
                                                                        type4 ? setEscritura(type4, index, index2) :
                                                                            ''
                                                    }
                                                    className="p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                } else {
                                    return <></>
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={(
                                                    individualElement && individualElement.value && individualElement.value !== 'undefined') ?
                                                    individualElement.value :
                                                    individualElement.value == '' ? individualElement.value :
                                                        type1 ? setEscritura(type1, index, index2) :
                                                            type2 ? setEscritura(type2, index, index2) :
                                                                type3 ? setEscritura(type3, index, index2) :
                                                                    type4 ? setEscritura(type4, index, index2) :
                                                                        ''
                                                } className="p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'T') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputText
                                                    id="username"
                                                    value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : ''}
                                                    className="p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                } else {
                                    return <></>
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : ''}
                                                className="p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }} />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'C') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Dropdown
                                                    value={individualElement.value}
                                                    options={individualElement.cmpValores}
                                                    placeholder="Selecciona una opción" className="w-full p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                    virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Dropdown
                                                value={individualElement.value}
                                                options={individualElement.cmpValores}
                                                placeholder="Selecciona una opción" className="w-full p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'D') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <Calendar
                                                    value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? new Date(individualElement.value) : ''}
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }}
                                                    locale="es"
                                                    dateFormat='dd/mm/yy'
                                                    ref={calendarRef}
                                                    onInput={(e) => handleInputChange(e, handleValueChange, index, index2)}
                                                />

                                                {/* <InputText id="username" className="p-inputtext-lg" /> */}
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <Calendar
                                                value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? new Date(individualElement.value) : ''}
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }}
                                                locale="es"
                                                dateFormat='dd/mm/yy'
                                                ref={calendarRef}
                                                onInput={(e) => handleInputChange(e, handleValueChange, index, index2)}
                                            />

                                            {/* <InputText id="username" className="p-inputtext-lg" /> */}
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'B') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class} flex align-items-center`}>
                                            <Checkbox
                                                inputId="ingredient1"
                                                name={individualElement.cmp_titulo}
                                                value={individualElement.cmp_titulo}
                                                onChange={(e) => { handleValueChange((individualElement && individualElement.value && individualElement.value !== 'undefined') ? !individualElement.value : true, index, index2) }}
                                                checked={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? true : false}
                                            />
                                            <label htmlFor="ingredient1" className="ml-2">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class} flex align-items-center`}>
                                        <Checkbox
                                            inputId="ingredient1"
                                            name={individualElement.cmp_titulo}
                                            value={individualElement.cmp_titulo}
                                            onChange={(e) => { handleValueChange((individualElement && individualElement.value && individualElement.value !== 'undefined') ? !individualElement.value : true, index, index2) }}
                                            checked={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? true : false}
                                        />
                                        <label htmlFor="ingredient1" className="ml-2">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'TA') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputTextarea
                                                    id="username"
                                                    value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : ''}
                                                    className="p-inputtext-lg"
                                                    onChange={(e) => { handleValueChange(e.target.value, index, index2) }}
                                                    rows={5}
                                                    cols={30}
                                                    style={{ resize: 'none' }}
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <span className="p-float-label">
                                            <InputTextarea
                                                id="username"
                                                value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? individualElement.value : ''}
                                                className="p-inputtext-lg"
                                                onChange={(e) => { handleValueChange(e.target.value, index, index2) }}
                                                rows={5}
                                                cols={30}
                                                style={{ resize: 'none' }}
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'M') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <span className="p-float-label">
                                                <InputNumber
                                                    value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? parseFloat(individualElement.value) : 0}
                                                    onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                    mode="currency"
                                                    currency="USD"
                                                    locale="en-US"
                                                />
                                                <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                            </span>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-12`} >
                                        <span className="p-float-label">
                                            <InputNumber
                                                value={(individualElement && individualElement.value && individualElement.value !== 'undefined') ? parseFloat(individualElement.value) : null}
                                                onChange={(e) => { handleValueChange(e.value, index, index2) }}
                                                mode="currency"
                                                currency="USD"
                                                locale="en-US"
                                            />
                                            <label htmlFor="username">{individualElement ? individualElement.cmp_titulo : ''}</label>
                                        </span>
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'DF') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <FolioDinamico
                                                cmpId={individualElement.cmp_id}
                                                frm={individualElement}
                                                indexes={[index, index2]}
                                                handleValueChange={handleValueChange}
                                                edicion={props.edicion}
                                                propiedadDetail={props.propiedadDetail}
                                                preTramiteFolio={props.preTramiteFolio}
                                                information={props.information}
                                                previousFolio={props.previousFolio}
                                                previousPayments={props.previousPayments}
                                                responseData={props.responseData}
                                                preTramite={props.preTramite}
                                                preTramiteDetail={props.preTramiteDetail}
                                                handleTitularesCollect={handleTitularesCollect}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <FolioDinamico
                                            cmpId={individualElement.cmp_id}
                                            frm={individualElement}
                                            indexes={[index, index2]}
                                            handleValueChange={handleValueChange}
                                            edicion={props.edicion}
                                            propiedadDetail={props.propiedadDetail}
                                            preTramiteFolio={props.preTramiteFolio}
                                            information={props.information}
                                            previousFolio={props.previousFolio}
                                            previousPayments={props.previousPayments}
                                            responseData={props.responseData}
                                            preTramite={props.preTramite}
                                            preTramiteDetail={props.preTramiteDetail}
                                            handleTitularesCollect={handleTitularesCollect}
                                        />
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'DT' || individualElement.cmp_tipo === 'DT3') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <I1Acto
                                                cmpId={individualElement.cmp_id}
                                                frm={individualElement}
                                                indexes={[index, index2]}
                                                handleValueChange={handleValueChange}
                                                edicion={props.edicion}
                                                propiedadDetail={props.propiedadDetail}
                                                preTramiteFolio={props.preTramiteFolio}
                                                information={props.information}
                                                previousFolio={props.previousFolio}
                                                previousPayments={props.previousPayments}
                                                responseData={props.responseData}
                                                preTramite={props.preTramite}
                                                preTramiteDetail={props.preTramiteDetail}
                                                handleDeleteTitularString={handleDeleteTitularString}
                                                tramite={props.tramiteDetail}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <I1Acto
                                            cmpId={individualElement.cmp_id}
                                            frm={individualElement}
                                            indexes={[index, index2]}
                                            handleValueChange={handleValueChange}
                                            edicion={props.edicion}
                                            propiedadDetail={props.propiedadDetail}
                                            preTramiteFolio={props.preTramiteFolio}
                                            information={props.information}
                                            previousFolio={props.previousFolio}
                                            previousPayments={props.previousPayments}
                                            responseData={props.responseData}
                                            preTramite={props.preTramite}
                                            preTramiteDetail={props.preTramiteDetail}
                                            handleDeleteTitularString={handleDeleteTitularString}
                                            tramite={props.tramiteDetail}
                                        />
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'DT2' && individualElement.cmp_funcion == 'folio') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <FolioAutomaticData
                                                cmpId={individualElement.cmp_id}
                                                frm={individualElement}
                                                indexes={[index, index2]}
                                                handleValueChange={handleValueChange}
                                                edicion={props.edicion}
                                                propiedadDetail={props.propiedadDetail}
                                                preTramiteFolio={props.preTramiteFolio}
                                                information={props.information}
                                                previousFolio={props.previousFolio}
                                                previousPayments={props.previousPayments}
                                                responseData={props.responseData}
                                                preTramite={props.preTramite}
                                                preTramiteDetail={props.preTramiteDetail}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <FolioAutomaticData
                                            cmpId={individualElement.cmp_id}
                                            frm={individualElement}
                                            indexes={[index, index2]}
                                            handleValueChange={handleValueChange}
                                            edicion={props.edicion}
                                            propiedadDetail={props.propiedadDetail}
                                            preTramiteFolio={props.preTramiteFolio}
                                            information={props.information}
                                            previousFolio={props.previousFolio}
                                            previousPayments={props.previousPayments}
                                            responseData={props.responseData}
                                            preTramite={props.preTramite}
                                            preTramiteDetail={props.preTramiteDetail}
                                        />
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'DT2') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <NuevoFolioTitulares
                                                cmpId={individualElement.cmp_id}
                                                frm={individualElement}
                                                indexes={[index, index2]}
                                                handleValueChange={handleValueChange}
                                                edicion={props.edicion}
                                                propiedadDetail={props.propiedadDetail}
                                                preTramiteFolio={props.preTramiteFolio}
                                                information={props.information}
                                                previousFolio={props.previousFolio}
                                                previousPayments={props.previousPayments}
                                                responseData={props.responseData}
                                                preTramite={props.preTramite}
                                                preTramiteDetail={props.preTramiteDetail}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <NuevoFolioTitulares
                                            cmpId={individualElement.cmp_id}
                                            frm={individualElement}
                                            indexes={[index, index2]}
                                            handleValueChange={handleValueChange}
                                            edicion={props.edicion}
                                            propiedadDetail={props.propiedadDetail}
                                            preTramiteFolio={props.preTramiteFolio}
                                            information={props.information}
                                            previousFolio={props.previousFolio}
                                            previousPayments={props.previousPayments}
                                            responseData={props.responseData}
                                            preTramite={props.preTramite}
                                            preTramiteDetail={props.preTramiteDetail}
                                        />
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'DT4') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <I12Acto
                                                cmpId={individualElement.cmp_id}
                                                titularesCollection={titularesCollection}
                                                titularesPool={titularesPool}
                                                setTitularesCollection={setTitularesCollection}
                                                handleTitularesCollect={handleTitularesCollect}
                                                addToCollection={addToCollection}
                                                handleValueChange={handleValueChange}
                                                indexes={[index, index2]}
                                                frm={individualElement}
                                                responseData={props.responseData}
                                                preTramiteFolio={props.preTramiteFolio}
                                                information={props.information}
                                                edicion={props.edicion}
                                                propiedadDetail={props.propiedadDetail}
                                                previousFolio={props.previousFolio}
                                                previousPayments={props.previousPayments}
                                                preTramite={props.preTramite}
                                                preTramiteDetail={props.preTramiteDetail}
                                                handleDeleteTitularString={handleDeleteTitularString}
                                                tramite={props.tramiteDetail}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <I12Acto
                                            cmpId={individualElement.cmp_id}
                                            titularesCollection={titularesCollection}
                                            titularesPool={titularesPool}
                                            setTitularesCollection={setTitularesCollection}
                                            handleTitularesCollect={handleTitularesCollect}
                                            addToCollection={addToCollection}
                                            handleValueChange={handleValueChange}
                                            indexes={[index, index2]}
                                            frm={individualElement}
                                            responseData={props.responseData}
                                            preTramiteFolio={props.preTramiteFolio}
                                            information={props.information}
                                            edicion={props.edicion}
                                            propiedadDetail={props.propiedadDetail}
                                            previousFolio={props.previousFolio}
                                            previousPayments={props.previousPayments}
                                            preTramite={props.preTramite}
                                            preTramiteDetail={props.preTramiteDetail}
                                            handleDeleteTitularString={handleDeleteTitularString}
                                            tramite={props.tramiteDetail}
                                        />
                                    </div>
                                )
                            }
                        } else if (individualElement.cmp_tipo === 'AC') {

                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`} >
                                            <CancelarActo
                                                cmpId={individualElement.cmp_id}
                                                frm={individualElement}
                                                indexes={[index, index2]}
                                                handleValueChange={handleValueChange}
                                                actoHistory={props.actoHistory}
                                                setSelected={props.setSelected}
                                                getActoInformationView={props.getActoInformationView}
                                                detail={props.propiedadDetail}
                                                tramite={props.tramite}
                                                setActoHistory={props.setActoHistory}
                                                actoHistoryClone={props.actoHistoryClone}
                                                setTramiteSelected={props.setTramiteSelected}
                                                information={props.information}
                                            />
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`} >
                                        <CancelarActo
                                            cmpId={individualElement.cmp_id}
                                            frm={individualElement}
                                            indexes={[index, index2]}
                                            handleValueChange={handleValueChange}
                                            actoHistory={props.actoHistory}
                                            setSelected={props.setSelected}
                                            getActoInformationView={props.getActoInformationView}
                                            detail={props.propiedadDetail}
                                            tramite={props.tramite}
                                            setActoHistory={props.setActoHistory}
                                            actoHistoryClone={props.actoHistoryClone}
                                            setTramiteSelected={props.setTramiteSelected}
                                            information={props.information}
                                        />
                                    </div>
                                )
                            }

                        } else if (individualElement.cmp_tipo === 'vacio') {
                            if (individualElement.cmp_padre) {
                                const isActive = findPadre(individualElement.cmp_padre, individualElement.cmp_padre_valor)
                                if (isActive) {
                                    return (
                                        <div className={`col-12 md:col-${individualElement.class}`}>
                                        </div>
                                    )
                                }
                            } else {
                                return (
                                    <div className={`col-12 md:col-${individualElement.class}`}>
                                    </div>
                                )
                            }
                        } else {
                            return ('')
                        }
                    })
                ))
            }
        </div>
    );

}

export default React.memo(DynamicForm);