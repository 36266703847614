import { Button } from 'primereact/button';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../serviceExample/ProductService';
import { Checkbox } from 'primereact/checkbox';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { useLocation, useNavigate } from 'react-router-dom';
import authGuardService from '../../../service/authGuardService';
import EncryptService from '../../../service/encrypt.service';
import ApiServices from '../../../service/apiServices';
import { Toast } from 'primereact/toast';

interface City {
    name: string;
}

const VenDupFolio = () => {
    const user = authGuardService.getUser()
    const toast = useRef<any>(null);

    const location = useLocation();

    const navigate = useNavigate();

    const folios = location.state.folios

    type rowHeaderInterface = {
        [key: string]: string
    }

    const rowHeadersProps: rowHeaderInterface = {
        "1": "usu_status",
        "2": "chk",
        "Folio": "prop_folio",
        "Clave Catastral": "prop_clave",
        "Dueño": "proptitutales",
        "Domicilio Registral": "prop_direccion",
        "C.P": "cp_cp",
        "Localidad": "cp_loc",
        "Municipio": "cp_mun",
        "Estado": "cp_edo",
        "Cruzamiento 1 y 2": "prop_cruzamientos",
        "Colonia": "cp_col",
        "N° Interior": "prop_int",
        "Calle": "prop_calle",
        "Medidas": "prop_medidas",
        "Construida": "prop_constr",
        "Superficie Total": "prop_suptotal"
    };
    const rowHeaders = Object.keys(rowHeadersProps).filter((f) => f !== "Carb");

    /* OLD */
    const [checkeds, setChecked] = useState<any>([]);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);

    const checkTemplate = (product: any, generalData: any) => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Checkbox inputId={product} onChange={e => {
                    let checkedEx = [...checkeds];
                    if (!checkedEx.includes(product))
                        checkedEx.push(product)
                    else
                        checkedEx = checkedEx.filter((c: any) => c !== product);
                    setChecked(checkedEx)
                }} checked={checkeds.includes(product)} />
                {generalData && generalData.prop_status == 2 && <p>Folio pendiente de validación</p>}
            </div>
        );
    };

    const checkTemplateBody = (text: any, header: string) => {
        return (header == "Folio") ? (
            <div>
                <a style={{ textDecoration: 'underline', cursor: 'pointer' }}>{text}</a>
            </div>
        ) : (
            <div>
                {text}
            </div>
        );
    };

    const sentDupAlert = () => {
        let datos
        datos = { "code": 202, props: checkeds, usu_id: user.usu_id, aldup_desc: `Alerta de los Folios ${JSON.stringify(checkeds)}` }

        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Observacion Enviada con Exito', life: 3000 });
            }
        }).catch(error => {
            toast.current.show({ severity: 'error', summary: 'Error al Guardar', detail: 'Hubo un error al generar la observación', life: 3000 });
        })
        ModalSetVisible(false)
    }

    function encontrarPropiedadPorFolio(array: any, prop_folio: any) {
        for (let propiedad of array) {
            if (propiedad.prop_id == prop_folio) {
                return propiedad;
            }
        }
        return null;
    }
    const footerContent = (
        <div>
            <Button label="Cancelar" onClick={() => ModalSetVisible(false)} severity="secondary" outlined />
            <Button label="Alertar" onClick={sentDupAlert} autoFocus />
        </div>
    );

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />

            <div className='col-12 md:col-12 pb-5'>
                <h3 className='color-violet'>Búsqueda de coincidencias</h3>
                <p>Se encontraros coincidencias en el folio.</p>
            </div>
            <div className='grid'>
                <div className='col-12 md:col-5'>
                    <span className="p-float-label">
                        <InputText id="username" className="p-inputtext-lg" />
                        <label htmlFor="username">Folio real, No catastral o Nombre de propietario</label>
                    </span>
                </div>
                <div className='col-12 md:col-5'>
                    <span className="p-float-label">
                        <InputText id="username" className="p-inputtext-lg" />
                        <label htmlFor="username">Folio OnLine</label>
                    </span>
                </div>
                <div className='col-12 md:col-2'>
                    <Button label="Buscar" />
                </div>
            </div>
            <div className='col-12 md:col-12 py-5'>
                <h3 className='color-violet'>Resultados</h3>
                <div className='flex justify-content-between'>
                    <div>
                        <p>Selecciona los folios para comparar las precodificadas, posteriormente selecciona los folios para generar una o varias observaciones o alertas.</p>
                    </div>
                    <div>
                        <p className='azul'><i className="pi pi-circle-fill"></i> Coincidencias encontradas</p>
                    </div>
                </div>
            </div>

            <div>
                <table>
                    <tbody>
                        {rowHeaders.map((header: string, index) => (
                            <tr key={header} >
                                <th style={{
                                    display: 'flex',
                                    padding: index == 0 ? '20px' : '10px',
                                    alignItems: 'center',
                                    marginRight: '18px',
                                    background: '#EEE'
                                }}>{header == '1' || header == '2' ? '' : header}</th>
                                {folios && folios.map((individualFolio: any) => {
                                    return (
                                        index > 0 ? (
                                            <td
                                                key={individualFolio.id}
                                                style={{
                                                    padding: '10px',
                                                    alignItems: 'center',
                                                    marginRight: '50px',
                                                    background: index % 2 == 0 ? 'rgb(238, 238, 238)' : '#FFF'
                                                }}
                                            >{checkTemplateBody(individualFolio[rowHeadersProps[header]], header)}</td>
                                        ) : (
                                            <td
                                                style={{
                                                    padding: '10px',
                                                    alignItems: 'center',
                                                    marginRight: '50px',
                                                    background: index % 2 == 0 ? 'rgb(238, 238, 238)' : '#EEE'
                                                }}
                                                key={individualFolio.prop_id}>{checkTemplate(individualFolio.prop_id, individualFolio)}</td>
                                        )
                                    )
                                }
                                )}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className='footer-folio flex justify-content-end'>
                <div className='flex col-6'>
                    <Button label="Generar alerta" className='p-button-outlined mr-4' onClick={() => { { ModalSetVisible(true) } }} disabled={checkeds && !(checkeds.length > 0)} />
                    {checkeds && checkeds.length > 0 && checkeds.length < 2 &&
                        <Button label="Siguiente" onClick={() => {
                            if (checkeds && checkeds.length > 0) {

                                let url
                                const folio = encontrarPropiedadPorFolio(location.state.folios, checkeds[0])
                                const navigateOptions = {
                                    state: {
                                        pagos: location.state.pagos,
                                        folios: [folio]
                                    }
                                };

                                if (location.state.onlyView) {
                                    url = '/ventanilla/view-folio'
                                } else {
                                    url = '/ventanilla/vt-tramite'
                                }

                                navigate(url, navigateOptions)
                            }
                        }} />
                    }
                </div>
            </div>

            {/*inicia dialog para generar una alerta*/}
            <Dialog header="Alerta de duplicidad" visible={modalVisible} style={{ width: '30vw' }} onHide={() => ModalSetVisible(false)} footer={footerContent}>
                <div className="container-fluid">
                    <div className="flex justify-content-center flex-wrap">
                        <div className='dup-alert flex justify-content-center align-content-center flex-wrap'>
                            <i className="pi pi-fw pi-exclamation-triangle" style={{ color: '#fff', fontSize: '30px' }}></i>
                        </div>
                        <h6 className='pt-5'>Haz seleccionado un folio que esta duplicado, alerta a las demás áreas que este folio es duplicado para iniciar la investigación y comparación de datos.</h6>
                    </div>
                </div>
            </Dialog>


        </section>

    );

}

export default React.memo(VenDupFolio);