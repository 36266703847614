import { Button } from 'primereact/button';
import { ConfirmPopup } from 'primereact/confirmpopup';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../../../service/apiServices';
import TabGenerales from './TabViewFolio/generales';
import { Calendar } from 'primereact/calendar';
import EncryptService from '../../../../service/encrypt.service';
import { Timeline } from 'primereact/timeline';
import { Panel } from 'primereact/panel';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputTextarea } from 'primereact/inputtextarea';
import { ProgressSpinner } from 'primereact/progressspinner';
import formatDate from '../../../../utilities/formatDate';
import { Steps } from 'primereact/steps';
import { useNavigate } from 'react-router-dom';
import { Toast } from 'primereact/toast';
import DynamicForm from '../../../actos/DynamicForm';
import configuration from '../../../../configurationFile';
import TimeLine from '../../../../Components/TimeLine/TimeLine';
import Solicitador from '../../../../refactor/PagoFolio/PagoResulFolio/Folio/DatosGenerales/SubComponentes/Solicitador';

const ViewBuscadorInterno = () => {
    const navigate = useNavigate();
    const toast = useRef<any>(null);

    const generalData = {
        "trm_id": "74",
        "trm_fecha": "2023-10-05T00:00:00.000Z",
        "trm_consecutivo": 1,
        "prop_id": "1",
        "usu_id": "54",
        "not_id": 5,
        "trm_solicitante": "Hugo Castañeda Salgado",
        "trm_escritura": "123456",
        "ptrm_id": "0",
        "trm_status": 1,
        "trm_update": "2023-10-05T09:44:19.353Z",
        "trm_analista": "59",
        "trm_firmante": null,
        "usu_nom": "Ventanilla ",
        "usu_pat": "Analista",
        "usu_mat": ".",
        "prop_clave": "ABC",
        "prop_folio": "10",
        "actos": "CI1, I39",
        "clavearea": "4",
        requestName: "",
        exist: true,
    }

    const [titulares, setTitulares] = useState([])
    const [actoHistory, setActoHistory] = useState()
    const [actoHistoryLoading, setActoHistoryLoading] = useState(true)

    useEffect(() => {
        // getTitulares()
        //getActoHistory()
    }, [generalData])

    const getTitulares = () => {
        let datos = {
            prop_id: generalData.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    function groupObjectsByTrmId(inputArray: any) {
        const groupedObjects: any = {};

        inputArray.forEach((obj: any) => {
            const trmId = obj.trm_id;

            if (!groupedObjects[trmId]) {
                groupedObjects[trmId] = {
                    trm_fecha: obj.trm_fecha,
                    trm_id: trmId,
                    history: []
                };
            }

            groupedObjects[trmId].history.push(obj);
        });

        return Object.values(groupedObjects);
    }

    const getActoHistory = () => {
        let datos = {
            prop_folio: generalData.prop_folio
        }
        ApiServices.apiPost('getHistorial', datos).then(async res => {
            const groupedResult: any = groupObjectsByTrmId(res.data.addenda);
            setActoHistory(groupedResult)
            setActoHistoryLoading(false)
        }).catch(error => {
            console.log(error)
        })
    }

    const [realFolio, setRealFolio] = useState(true)
    const [requester, setRequester] = useState(true)
    const [currentActiveTab, setActiveTab] = useState(0)
    const [preTramiteDetail, setPreTramiteDetail] = useState<any>({ docs: [] })

    const requestPreTramiteDetail = () => {
        // cargar docs? 
        setPreTramiteDetail({ docs: [] })
    }
    const handleSentRevision = () => {
        /* let datos
         datos = { "code": 504, trm_id: [previousFolio.trm_id], usu_id: user.usu_id }
         datos = EncryptService.encryptData(JSON.stringify(datos));
         ApiServices.processRequestAdd(datos).then(async res => {
             if (res.data.valido == 1) {
                 ModalSetVisible(true)
             }
         }).catch(error => {
             console.log(error)
         })*/
    }

    const [visible, setVisible] = useState<boolean>(false);
    const [modalVisible, ModalSetVisible] = useState<boolean>(false);
    const [modalBorradorGuardado, setModalBorradorGuardado] = useState(false)
    const buttonEl = useRef<any>(null);
    const buttonConfirm = useRef<any>(null);
    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const acceptConfirm = () => {
        handleSentRevision()
        setVisibleConfirm(false)
        ModalSetVisible(true) //test
    };

    const rejectConfirm = () => {
        setVisibleConfirm(false)
    };

    const accept = () => {
        ModalSetVisible(true)
    };

    const reject = () => {
        toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    };

    const showSuccess = () => {
        toast.current.show({ severity: 'success', summary: 'Guardado con éxito', detail: 'Se ha guardado tu trámite', life: 3000 });
    }

    const [timeLine, setTimeLine] = useState<any>()

    const getTimeLine = (id: any) => {
        if (id) {
            let datos = {
                trm_id: id,
            }
            ApiServices.apiPost('getTimeLine', datos).then(async res => {
                if (res.data.valido === 1 && res.data.addenda && res.data.addenda.length > 0) {
                    setTimeLine(res.data.addenda[0])
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const events1 = [
        { label: 'Capturado' },
        { label: 'Asignado' },
        { label: 'Analizando' },
        { label: 'A-R-C' },
        { label: 'Firmado' }
    ];

    const events2 = [
        { label: 'Capturado', tooltip: timeLine ? timeLine.capturista : '', time: timeLine ? timeLine.trm_fecha : '' },
        { label: 'Asignado', tooltip: timeLine ? timeLine.analista : '' },
        { label: 'Analizando', tooltip: '', time: timeLine ? timeLine.trm_analizado : '' },
        { label: 'A-R-C', tooltip: timeLine ? timeLine.statusARC : '', time: timeLine ? timeLine.fechahoraARC : '' },
        { label: 'Firmado', tooltip: timeLine ? timeLine.trm_firmador : '', time: timeLine ? timeLine.trm_firmado : '' }
    ];

    const HeaderRechazado = (rowData: any) => {
        return (
            <>
                <div className='grid'>
                    <div className='flex col-10 md:col-10'>
                        <div className='flex align-items-center justify-content-center'>Rechazado</div>
                        <div className='flex align-items-center justify-content-center ml-2' style={{ fontSize: '12px' }}>12/Mar/2022</div>
                    </div>
                    <div className='flex col-2 md:col-2 justify-content-end align-content-center'>
                        <div className='flex align-items-center justify-content-center'>
                            <Button icon="pi pi-minus" rounded text severity="secondary" onClick={() => { }} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
    const dt = useRef<any>(null);
    const footerActos = (
        <ColumnGroup>
            <Row>
                <Column className='text-right' footer="Monto de derechos:" colSpan={3} />
                {/* <Column style={{'textAlign':'right'}} footer={`${(props && props.total) ? parseFloat(props.total).toFixed(0) : '$0.00'} MXN`} /> */}
                <Column />
            </Row>
        </ColumnGroup>
    ); const pagoBodyTemplate = (payment: any) => {
        if (payment && payment.valormx)
            return payment.valormx.toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
            });
        else return ('')
    }

    const fechaBodyTemplate = (payment: any) => {
        if (tramiteDetail.tramite.trm_fecha) {
            return `${formatDate(tramiteDetail.tramite.trm_fecha)}`
        }
    }

    const op = useRef<any>(null);

    const HeaderEstatus = (rowData: any) => {
        return (
            <>
                <div className='flex'>
                    <div className='flex align-items-center justify-content-center'>Estatus</div>
                    <Button icon="pi pi-info" rounded text severity="secondary" onClick={(e) => {
                        if (op && op.current != null) {
                            op.current.toggle(e)
                        }
                    }} />
                    <OverlayPanel ref={op}>
                        <div className='' style={{ fontSize: '13px', fontWeight: 'bold' }}>
                            Niveles de estatus
                        </div>
                        <div className='p-3'>
                            <div className='flex align-items-center justify-content-start pt-2 pb-3 pr-5' style={{ borderBottom: '1px solid #ddd', fontSize: '13px' }}>
                                <div className='pr-5'>
                                    <i className="pi pi-circle-fill" style={{ color: 'gray' }}></i>
                                </div>
                                <div>
                                    Evaluando
                                </div>
                            </div>
                            <div className='flex align-items-center justify-content-start pt-3 pb-3' style={{ borderBottom: '1px solid #ddd', fontSize: '13px' }}>
                                <div className='pr-5'>
                                    <i className="pi pi-circle-fill" style={{ color: 'green' }}></i>
                                </div>
                                <div>Aceptado</div>
                            </div>
                            <div className='flex align-items-center justify-content-start pt-3 pb-3' style={{ borderBottom: '1px solid #ddd', fontSize: '13px' }}>
                                <div className='pr-5'>
                                    <i className="pi pi-circle-fill" style={{ color: 'red' }}></i>
                                </div>
                                <div>Rechazado</div>
                            </div>
                            <div className='flex align-items-center justify-content-start pt-3 pb-3 pr-5' style={{ borderBottom: '1px solid #ddd', fontSize: '13px' }}>
                                <div className='pr-5'>
                                    <i className="pi pi-circle-fill" style={{ color: 'blue' }}></i>
                                </div>
                                <div>Firmado</div>
                            </div>
                        </div>
                    </OverlayPanel>
                </div>
            </>
        );
    }

    const EyeTemplate = (rowData: any) => {
        return (
            <div className='text-center'>
                <Button className="p-button-text" icon="pi pi-eye" onClick={() => { searchFormulario(rowData) }} />
            </div>
        );
    }

    function transformarArray(arrayOriginal: any) {
        return arrayOriginal.map((item: any) => {
            return {
                label: item.trm_consecutivo,
                value: item.trm_id
            };
        });
    }

    function moveRight() {
        const selectedIndex = (valueSelected + 1) % foliosConsecutivo.length;
        updateSelected(selectedIndex);
    }
    function moveleft() {
        const selectedIndex = (valueSelected - 1 + foliosConsecutivo.length) % foliosConsecutivo.length;
        updateSelected(selectedIndex);
    }
    function updateSelected(selectedIndex: any) {
        setValueSelected(selectedIndex);
        setSelectedFolio(foliosConsecutivo[selectedIndex].value)
        searchTramite(foliosConsecutivo[selectedIndex].value)
    }

    const handleDropdownChange = (e: any) => {
        setSelectedFolio(e.value)
        var folioS = foliosConsecutivo.find((folio: any) => folio.value == e.value);
        const selectedIndex = (folioS.label - 1 + foliosConsecutivo.length) % foliosConsecutivo.length;
        updateSelected(selectedIndex);
    };

    function encontrarActividadPorId(acto_id: any) {
        const resultado = actos.find((singleActo: any) => singleActo.act_id === acto_id);
        return resultado || {};
    }

    const [searchDate, setSearchDate] = useState<any>()
    const [foliosConsecutivo, setFoliosConsecutivos] = useState<any>([])
    const [selectedFolio, setSelectedFolio] = useState<any>()
    const [valueSelected, setValueSelected] = useState<any>(0)
    const [tramiteDetail, setTramiteDetail] = useState<any>()
    const [actos, setActos] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const calendarRef = useRef(null);

    const handleInputChange = (e) => {
        const inputValue = e.target.value;
        const formattedDate = formatDateString(inputValue);
        if (formattedDate) {
            searchFoliosByDate({ value: new Date(formattedDate) })
            setSearchDate(new Date(formattedDate));
        } else {
            setSearchDate(null);
        }
        if (calendarRef.current && calendarRef.current.inputElement) {
            calendarRef.current.inputElement.value = formattedDate || inputValue;
        }
    };
    const formatDateString = (input) => {
        if (input.length === 8) {
            const day = input.substring(0, 2);
            const month = input.substring(2, 4);
            const year = input.substring(4, 8);
            return `${month}/${day}/${year}`;
        }
        return null;
    };

    const searchFoliosByDate = (e: any) => {
        if (e.value) {

            setSearchDate(e.value)

            var año = e.value.getFullYear();
            var mes = (e.value.getMonth() + 1).toString().padStart(2, '0'); // Añadir cero al principio si el mes es de un solo dígito
            var día = e.value.getDate().toString().padStart(2, '0');
            var fechaFormateada = año + '-' + mes + '-' + día;

            let datos = {
                trm_fecha: fechaFormateada
            }
            ApiServices.apiPost('foliosFecha', datos).then(async res => {
                if (res.data.addenda && res.data.addenda.length > 0) {
                    const newSet = transformarArray(res.data.addenda)
                    setFoliosConsecutivos(newSet)
                    setSelectedFolio(newSet[0].value)
                    setValueSelected(0)
                    searchTramite(newSet[0].value)
                } else {
                    toast.current.show({ severity: 'warn', summary: 'Sin Resultados', detail: 'No hay folios para ese dia', life: 3000 });
                    setFoliosConsecutivos([])
                    setSelectedFolio(null)
                    setTramiteDetail(null)
                    setNotariaSeleccionada(null)
                }
            }).catch(error => {
                console.log(error)
            })
        }
    }

    const searchTramite = (id: any) => {
        setNotariaSeleccionada(null)
        getTimeLine(id)
        setIsLoading(true)
        let datos
        datos = { code: 204, trm_id: id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            setTramiteDetail(res.data.addenda)
            buscarNotaria(res?.data?.addenda?.tramite?.not_id)

            if (res.data.valido === 1) {
                let datos = {
                    prop_id: res.data.addenda.tramite.prop_id
                }
                ApiServices.apiPost('getBuscaPropiedad', datos).then(async res2 => {
                    if (res2.data.valido === 1) {
                        if (res2.data.addenda && res2.data.addenda.length > 0) {
                            res.data.addenda.more = res2.data.addenda[0]
                            if (res.data.addenda.actos && res.data.addenda.actos.length > 0) {
                                const newArray = res.data.addenda.actos.map((singleActo: any) => {
                                    const newActo = encontrarActividadPorId(singleActo.acto_id)
                                    return { ...singleActo, ...newActo }
                                })
                                res.data.addenda.newActos = newArray
                            }
                            setIsLoading(false)
                        }
                        else {
                            res.data.addenda.more = []
                            if (res.data.addenda.actos && res.data.addenda.actos.length > 0) {
                                const newArray = res.data.addenda.actos.map((singleActo: any) => {
                                    const newActo = encontrarActividadPorId(singleActo.acto_id)
                                    return { ...singleActo, ...newActo }
                                })
                                res.data.addenda.newActos = newArray
                            }
                            setIsLoading(false)
                        } 
                    }
                    setIsLoading(false)

                }).catch(error => {
                    setIsLoading(false)
                })
            }
        }).catch(error => {
            setIsLoading(false)
        })
    }

    function formatearHora(fechaString: any) {
        const fecha = new Date(fechaString);
        const horas = fecha.getUTCHours().toString().padStart(2, '0');
        const minutos = fecha.getUTCMinutes().toString().padStart(2, '0');
        const segundos = fecha.getUTCSeconds().toString().padStart(2, '0');

        return `${horas}:${minutos}:${segundos}`;
    }

    const [listadoNotarios, setListadoNotarios] = useState([])

    const getActos = () => {
        let datos = {}
        ApiServices.apiPost('catActo', datos).then(async res => {
            setActos(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const getNotarios = () => {
        let datos = {}
        ApiServices.apiPost('catNotarios', datos).then(async res => {
            setListadoNotarios(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getActos()
        getNotarios()
    }, [])

    const [dynamicFormInformation, setDynamicFormInformation] = useState()
    const [visibleActoView, setVisibleActoView] = useState<boolean>(false);
    const [selected, setSelected] = useState<any>('')
    const [dynamicInformationParent, setDynamicInformationParent] = useState()
    const [loading, setLoading] = useState(false)

    const searchFormulario = (tramite: any) => {
        setLoading(true)
        let datos = {
            ptrm_id: 0,
            trm_id: tramite.trm_id
        }
        ApiServices.apiPost('getFormatosResp', datos).then(async res => {
            if (res.data.valido === 1 && res.data.addenda.length > 0) {
                const found = res.data.addenda.find((objeto: any) => objeto.act_id == tramite.act_id);
                if (found && found.frmu_id) {
                    let datos = {
                        act_id: tramite.act_id
                    }
                    ApiServices.apiPost('getCampos', datos).then(async res => {
                        setDynamicFormInformation(res.data.addenda)
                        let merged = { ...tramite };
                        merged = { ...merged, ...found };
                        setSelected(merged)
                        setVisibleActoView(true)
                        setLoading(false)
                    }).catch(error => {
                        console.log(error)
                    })
                }
            } else {
                setLoading(false)
                toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Formulario no encontrado', life: 3000 });
            }
        }).catch(error => {
            setLoading(false)
            toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Hubo un error en el servidor, intente de nuevo mas tarde', life: 3000 });
        })
    }

    const [buttonLoadingPDF, SetButtonPDFLoading] = useState(false)

    const downloadPDF = async (trm: any) => {
        SetButtonPDFLoading(true)
        let datos
        datos = { "code": 213, "trm_id": tramiteDetail.tramite.trm_id }
        datos = EncryptService.encryptData(JSON.stringify(datos));
        ApiServices.processRequestAdd(datos).then(async res => {
            if (res.data.valido == 1) {
                SetButtonPDFLoading(false)
                if (res.data.valido == 0) {
                    toast.current?.show({ severity: 'error', summary: 'Error', detail: 'Error', life: 3000 });
                } else {
                    window.open(`${configuration.url}${res.data.addenda}`)
                }
            } else {
                SetButtonPDFLoading(false)
            }
        }).catch(error => {
            SetButtonPDFLoading(false)
            console.log(error)
        })
    }

    const [notariaSeleccionada, setNotariaSeleccionada] = useState<any>(null) // Notaria Seleccionada

    const buscarNotaria = (not_id) => {
        if (not_id && not_id != 0)
            for (let notario of listadoNotarios) {
                if (notario.not_id == not_id) {
                    setNotariaSeleccionada(notario)
                }
            }
    }

    return (
        <section className='container-fluid'>
            <Toast ref={toast} />
            <div className='grid'>
                <div className='col-12 md:col-12'>
                    <div className='grid'>
                        <div className='col-12 md:col-3'>
                            <span className="p-float-label">
                                {/* Input Done */}
                                <Calendar
                                    value={searchDate}
                                    onChange={searchFoliosByDate}
                                    className="p-inputtext-lg"
                                    locale="es"
                                    dateFormat="dd/mm/yy"
                                    ref={calendarRef}
                                    onInput={(e) => handleInputChange(e)}
                                />
                                <label htmlFor="date">Fecha</label>
                            </span>
                        </div>
                        {foliosConsecutivo && foliosConsecutivo.length > 0 &&
                            <div className='flex align-items-center justify-content-start col-3 md:col-3'>
                                <div className='' style={{ marginLeft: 5, marginRight: 5 }} onClick={moveleft}>
                                    <Button icon="pi pi-chevron-circle-left" rounded text aria-label="Filter" />
                                </div>
                                <div className='col-6 md:col-12'>
                                    <span className="p-float-label">
                                        <Dropdown
                                            options={foliosConsecutivo}
                                            value={selectedFolio}
                                            onChange={handleDropdownChange}
                                            filter
                                            showClear
                                            filterBy="label"
                                            className="p-inputtext-lg"
                                            emptyFilterMessage={'Sin Resultados'}
                                            virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                        />
                                        <label htmlFor="dd-city">Control Interno</label>
                                    </span>
                                </div>
                                <div className='' style={{ marginLeft: 5, marginRight: 5 }} onClick={moveRight}>
                                    <Button icon="pi pi-chevron-circle-right" rounded text aria-label="Filter" />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {isLoading ?

                <ProgressSpinner />
                :
                <>
                    <div className='grid' style={{ marginTop: 20 }}>
                        <div className='col-12 md:col-12'>
                            <div className='grid'>
                                <div className='col-12 md:col-4'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="ns"
                                            className="p-inputtext-lg"
                                            value={tramiteDetail ? tramiteDetail.tramite.trm_solicitante : ''}
                                            placeholder='Nombre Solicitante'
                                            onChange={(e) => { }}
                                        />
                                        <label htmlFor="ns">Nombre Solicitante</label>
                                    </span>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="username"
                                            className="p-inputtext-lg"
                                            placeholder="Número comprobante de pago"
                                            value={(tramiteDetail && tramiteDetail.pagos && tramiteDetail.pagos.length > 0) ? `${tramiteDetail.pagos[0].pago_serie} - ${tramiteDetail.pagos[0].pago_folio}` : ''}
                                        />
                                        <label htmlFor="username">Número comprobante de pago</label>
                                    </span>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="username"
                                            className="p-inputtext-lg"
                                            placeholder="Número de escritura"
                                            value={(tramiteDetail && tramiteDetail.tramite) ? tramiteDetail.tramite.trm_escritura : ''}
                                            onChange={(e) => { }}
                                        />
                                        <label htmlFor="username">Número de escritura</label>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex justify-content-between'>
                        {/* cuando sean folios de calidad aparecera el color verde se mostrara como el div, si no. se negara el small y el div 0.1 siguiente */}
                        <div className='col-12 md:col-9'>
                            <h3 className='color-violet'>Detalle del tramite</h3>
                        </div>
                        <div className='col-12 md:col-2 flex justify-content-center'>
                            <p>Hora de registro: {tramiteDetail ? formatearHora(tramiteDetail.tramite.trm_hora) : ''}</p>
                        </div>
                    </div>
                    <div className=''>
                        <div className='flex justify-content-between'>
                            <div className='grid col-9 md:col-9'>
                                <div className='col-12 md:col-4'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="nfolio"
                                            value={tramiteDetail ? tramiteDetail?.more?.prop_folio : ''}
                                            className="p-inputtext-lg"
                                            placeholder="N° Folio"
                                        />
                                        <label htmlFor="nfolio">N° Folio</label>
                                    </span>
                                </div>
                                <div className='col-12 md:col-4'>
                                    <span className="p-float-label">
                                        <InputText
                                            id="cvecatastral"
                                            value={tramiteDetail ? tramiteDetail?.more?.prop_clave : ''}
                                            className="p-inputtext-lg"
                                            placeholder="Clave catastral"
                                        />
                                        <label htmlFor="cvecatastral">Clave catastral *</label>
                                    </span>
                                </div>
                            </div>

                            <div className='col-2 md:col-2 flex justify-content-center'>
                                {tramiteDetail && tramiteDetail.tramite &&
                                    <Button style={{ maxWidth: '150px', marginRight: 10 }} size="large" label="Descargar Boleta de Ingreso" onClick={downloadPDF} />
                                }
                                {tramiteDetail && tramiteDetail.tramite &&
                                    <Button style={{ maxWidth: '150px', marginLeft: 10 }} size="large" label="Ver Folio" onClick={() => {
                                        navigate('/interno/view-folio', {
                                            state: {
                                                folio: tramiteDetail.tramite
                                            }
                                        })
                                    }} />
                                }
                            </div>
                        </div>
                        <div className='flex align-items-center justify-content-center'>
                            <div className='col-7 md:col-7'>
                                {timeLine &&
                                    <TimeLine timeLine={timeLine} />
                                }
                            </div>
                        </div>
                        <div className='grid'>
                            <div className='col-12 md:col-12'>
                                <span className="p-float-label">
                                    <InputTextarea
                                        id="tomolibro"
                                        value={tramiteDetail ? tramiteDetail?.more?.prop_direccion : ''}
                                        className="p-inputtext-lg"
                                        placeholder="Ubicación inmueble ó Razón social"
                                    />
                                    <label htmlFor="tomolibro">Ubicación inmueble ó Razón social</label>
                                </span>
                            </div>
                        </div>

                        <div className='grid'>
                            <div className='col-12'>
                                <hr />
                                <h5>Solicitante</h5>
                            </div>
                            <div className='col-12 md:col-12'>
                                <div className='grid'>
                                    <div className='col-12 md:col-12'>

                                        <Solicitador
                                            data={{
                                                view: true,
                                                solicitador: tramiteDetail ? tramiteDetail?.tramite?.trm_solicitante : '',
                                                notariaSeleccionada: notariaSeleccionada,
                                                catalogoNotarias: listadoNotarios,
                                                numeroEscritura: tramiteDetail ? tramiteDetail?.tramite?.trm_escritura : '',
                                                titulares: false,
                                                disableEscritura: true
                                            }}
                                            setSolicitador={() => { console.log('') }}
                                            seleccionarTitular={() => { console.log('') }}
                                            setNotariaSeleccionada={() => { console.log('') }}
                                            setNumeroEscritura={() => { console.log('') }}
                                        />
                                    </div>
                                    {/* <div className='col-12 md:col-5'>
                                        <span className="p-float-label">
                                            <Dropdown
                                                inputId="dd-city"
                                                optionLabel="not_fedata"
                                                filter
                                                showClear
                                                filterBy="not_fedata"
                                                className="p-inputtext-lg"
                                                virtualScrollerOptions={{ disabled: false, scrollHeight: '150', itemSize: 40 }}
                                            />
                                            <label htmlFor="dd-city">Notario</label>
                                        </span>
                                    </div>
                                    <div className='col-12 md:col-5'>
                                        <span className="p-float-label">
                                            <InputText
                                                id="username"
                                                className="p-inputtext-lg"
                                                placeholder="Solicitante"
                                                value={tramiteDetail ? tramiteDetail?.tramite?.trm_solicitante : ''}
                                            />
                                            <label htmlFor="username">Solicitante*</label>
                                        </span>
                                    </div> */}
                                    <div className='pt-4 col-12 md:col-12'>
                                        <DataTable ref={dt} value={tramiteDetail && tramiteDetail.newActos ? tramiteDetail.newActos : []} footerColumnGroup={footerActos}
                                            dataKey="id" paginator rows={5} className="datatable-responsive"
                                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                                            emptyMessage="No products found.">
                                            <Column field="act_clave" header="Clave" /* body={codeBodyTemplate} */ ></Column>
                                            <Column field="act_nombre" header="Detalle acto" /* body={nameBodyTemplate} */ ></Column>
                                            <Column field="" header="Fecha de pago" body={fechaBodyTemplate} ></Column>
                                            <Column field="valormx" header="Derechos" body={pagoBodyTemplate} ></Column>
                                            <Column field="" header={HeaderEstatus} ></Column>
                                            <Column field="" body={EyeTemplate} ></Column>
                                        </DataTable>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            {/* Modal Ver Acto */}
            <Dialog
                header={`${(selected && selected.act_nombre) || ''}`}
                maximizable
                visible={visibleActoView}
                style={{ width: '50%' }}
                onHide={() => setVisibleActoView(false)}
            >
                <DynamicForm informationForm={dynamicFormInformation} information={selected} setDynamicInformationParent={setDynamicInformationParent} preTramiteDetail={tramiteDetail} />
            </Dialog>
        </section>
    );
}

export default React.memo(ViewBuscadorInterno);


