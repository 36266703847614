import { useNavigate } from 'react-router-dom';
import AppMenuNotario from './VerticalMenu/AppMenuNotario';
import { classNames } from 'primereact/utils';
import authGuardService from '../../../service/authGuardService';
import moment from 'moment'
import { useEffect, useState } from 'react';
import ApiServices from '../../../service/apiServices';

const TopBarNotario = (props: any) => {

    require('moment/locale/es');
    moment.locale('es');

    const user = authGuardService.getUser()

    function obtenerFechaActual() {
        const fechaActual = moment().format('D MMMM YYYY');
        return fechaActual;
    }

    const onTopbarSubItemClick = (event: any) => {
        authGuardService.logout()
        event.preventDefault();
        navigate('/login')
    }

    const navigate = useNavigate();

    const [notifications, setNotifications] = useState([])

    useEffect(() => {
        getDesfirmarNotifications()
    }, [])

    const getDesfirmarNotifications = () => {
        let datos
        datos = { usu_id: user.usu_id }
        ApiServices.apiPost('getNotificacion', datos).then(async res => {
            setNotifications(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    const readNotification = (notification: any) => {
        let datos
        datos = {
            usu_id: user.usu_id,
            not_id: notification.not_id
        }
        ApiServices.apiPost('setViewNotificacion', datos).then(async res => {
            getDesfirmarNotifications()
        }).catch(error => {
            console.log(error)
        })
    }

    return (

        <div className="layout-topbar">
            <div className="layout-topbar-left" >
                <button className="topbar-menu-button p-link" onClick={props.onMenuButtonClick}>
                    <i className="pi pi-bars"></i>
                </button>

                <button className="p-link" onClick={() => navigate('/')}>
                    <img className='logo' src={`/assets/img/logo.png`} alt="logo" />
                </button>
            </div>

            <AppMenuNotario model={props.items} menuMode={props.menuMode} colorScheme={props.colorScheme} menuActive={props.menuActive} activeInlineProfile={props.activeInlineProfile}
                onSidebarMouseOver={props.onSidebarMouseOver} onSidebarMouseLeave={props.onSidebarMouseLeave} toggleMenu={props.onToggleMenu} onChangeActiveInlineMenu={props.onChangeActiveInlineMenu}
                onMenuClick={props.onMenuClick} onRootMenuItemClick={props.onRootMenuItemClick} onMenuItemClick={props.onMenuItemClick} />

            <div className="layout-topbar-right">
                <ul className="layout-topbar-right-items">
                    <li id="profile" className={classNames('profile-item', { 'active-topmenuitem': props.topbarMenuActive })}>
                        <button className="p-link" onClick={props.onTopbarItemClick}>
                            <img src="../../../../assets/layout/images/profile-image.png" alt="profile" />
                        </button>

                        <ul className="fadeInDown">
                            <li role="menuitem">
                                <button className="p-link" onClick={() => { navigate('/notarios/not-perfil') }}>
                                    <i className="pi pi-fw pi-user"></i>
                                    <span>Perfil</span>
                                </button>
                            </li>
                            <li role="menuitem">
                                <button className="p-link" onClick={onTopbarSubItemClick}>
                                    <i className="pi pi-fw pi-sign-out"></i>
                                    <span>Cerrar sesión</span>
                                </button>
                            </li>
                        </ul>
                    </li>

                    <li>
                        <div className='nav-user px-3 mx-3'>
                            <h5 className='black'>{user ? `${user.usu_nom} ${user.usu_pat}` : ''}</h5>
                            <small>{obtenerFechaActual()}</small>
                        </div>
                    </li>

                   {/*  <li id="notification" className={classNames('notification-item', { 'active-topmenuitem': props.notificationMenuActive })}>
                        <button className="p-link" onClick={props.onNotificationItemClick}>
                            <i className="topbar-icon pi pi-fw pi-bell"></i>
                            <span className="topbar-badge">{notifications ? notifications.length : 0}</span>
                            <span className="topbar-item-name">Notifications</span>
                        </button>

                        <ul className="fadeInDown notificacion" >
                            {
                                notifications && notifications.length <= 0 &&

                                <li role="menuitem">
                                    <a>
                                        <h6><i className="pi pi-fw pi-circle-fill"></i>Sin Notificaciones</h6>
                                    </a>
                                </li>
                            }
                            {
                                notifications && notifications.length > 0 && notifications.map((singleNotification: any) => {
                                    return (
                                        <li role="menuitem" onClick={() => { readNotification(singleNotification) }}>
                                            <a>
                                                <h6><i className="pi pi-fw pi-circle-fill"></i>{singleNotification.not_titulo}</h6>
                                                <p>{singleNotification.not_texto}</p>
                                            </a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </li> */}
                </ul>
            </div>
        </div >
    )
}

export default TopBarNotario;