import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useRef, useState } from 'react';
import ProductService from '../../../../../serviceExample/ProductService';
import ApiServices from '../../../../../service/apiServices';

interface City {
    name: string;
}

const TabTitulares = (props: any) => {
    const [titulares, setTitulares] = useState([])

    const getTitulares = () => {
        let datos = {
            prop_id: props.generalData.prop_id
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            setTitulares(res.data.addenda)
        }).catch(error => {
            console.log(error)
        })
    }

    useEffect(() => {
        getTitulares()
    }, []);

    return (
        <section>
            <div className='col-12 md:col-12'>
                <h3 className='color-violet'>Titulares</h3>
                <p>Titulares que son parte de este terreno.</p>
            </div>

            <div className='pt-4'>
                <DataTable
                    value={titulares}
                    dataKey="id"
                    rows={5}
                    className="datatable-responsive"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                    emptyMessage="No products found."
                >
                    <Column field="ptit_nom" header="Nombre" sortable  ></Column>
                    <Column field="ptit_pat" header="Primer apellido" sortable  ></Column>
                    <Column field="ptit_mat" header="Segundo apellido" ></Column>
                    <Column field="ptit_rfc" header="RFC"  ></Column>
                    <Column field="ptit_nuda" header="Nuda%"  ></Column>
                    <Column field="ptit_usufructo" header="Usufructo%"  ></Column>
                </DataTable>
            </div>

        </section>
    );
}

export default React.memo(TabTitulares);