import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Column, ColumnEditorOptions } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { Panel } from 'primereact/panel';
import React, { useEffect, useRef, useState } from 'react';
import ApiServices from '../../service/apiServices';
import { InputNumber } from 'primereact/inputnumber';
import EncryptService from '../../service/encrypt.service';
import authGuardService from '../../service/authGuardService';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Tag } from 'primereact/tag';
import { ConfirmPopup } from 'primereact/confirmpopup';

const I12Acto = (props: any) => {
    const user = authGuardService.getUser()

    const prop_id = props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
        props.responseData ? props.responseData.prop_id :
            props.previousFolio ? props.previousFolio[0].prop_id :
                props.generalData ? props.generalData.prop_id :
                    props.propiedadDetail ? props.propiedadDetail.prop_id :
                        0

    const columns = [
        { field: 'prop_id', header: 'ID' },
        { field: 'prop_folio', header: 'Folio' },
        { field: 'ptit_nom', header: 'Nombre' },
        { field: 'ptit_pat', header: 'Apellido Paterno / Razón Social' },
        { field: 'ptit_mat', header: 'Apellido Materno' },
        { field: 'ptit_rfc', header: 'RFC' },
        { field: 'cmpdt_curp', header: 'CURP' },
        { field: 'ptit_nuda', header: 'Nuda Actual %' },
        { field: 'nuda_enaje', header: 'Nuda Enaje %' },
        { field: 'nuda_adquiere', header: 'Nuda Adquiere %' },
        { field: 'ptit_usufructo', header: 'Usofructo Actual %' },
        { field: 'usufructo_enaje', header: 'Usofructo Enaje %' },
        { field: 'usufructo_adquiere', header: 'Usofructo Adquiere %' },
        { field: 'cmptd_representacion', header: 'Representación' }
    ];

    const titulares = props && props.titularesCollection && props.titularesCollection.length > 0 ? props.titularesCollection : [];
    const [titularesSeleccionados, setTitularesSeleccionados] = useState([])

    const [data, setData]: any = useState({
        concepto: { value: '', valid: false },
        paterno: { value: '', valid: false },
        materno: { value: '', valid: false },
        nombre: { value: '', valid: false },
        rfc: { value: '', valid: false },
        curp: { value: '', valid: false },
        nuda: { value: 0, valid: false },
        usufructo: { value: 0, valid: false },
        representacion: { value: '', valid: false },
    })

    const setValues = (jsonValue: any, value: any) => {
        const cloneJson = JSON.parse(JSON.stringify(data))
        cloneJson[jsonValue].value = value
        cloneJson[jsonValue].valid = value?.toString().length > 0
        setData(cloneJson)
    }

    function searchByExactName(text) {
        const types = [
            { name: 'Aplicacion por gananciales', code: '1' },
            { name: 'Herederos', code: '2' },
            { name: 'Legatarios', code: '3' }
        ]
        const lowerCaseText = text.toLowerCase();

        return types.filter(item => item.name.toLowerCase() === lowerCaseText);
    }

    const findSavedFolio = (folio) => {
        if (folio && folio != 0 && props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0) {
            return [...props.tramite.hijos, props.tramite.tramite].find(element => element.prop_folio == folio) || 0;
        } else {
            return [props.tramite.tramite].find(element => element.prop_folio == folio) || 0;
        }
    };

    const formattTitulares = (titulares) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))

        if (cloneTitulares && cloneTitulares.length > 0) {
            const newTitulares = cloneTitulares.map((singleTitular) => {
                const type = singleTitular && singleTitular.cmpdt_concepto ? searchByExactName(singleTitular.cmpdt_concepto) : ''
                const folio = findSavedFolio(singleTitular.cmptd_valor)
                return {
                    cmptdt_id: singleTitular.cmptdt_id || 0,
                    cmpdt_concepto: '',
                    ptit_nom: singleTitular.cmpdt_nombre,
                    ptit_pat: singleTitular.cmpdt_paterno_rs,
                    ptit_mat: singleTitular.cmpdt_materno,
                    ptit_rfc: singleTitular.cmpdt_rfc,
                    ptit_nuda: singleTitular.cmpdt_nuda,
                    nuda_adquiere: singleTitular.cmpdt_nuda_adquiere,
                    cmpdt_curp: singleTitular.cmpdt_curp,
                    nuda_enaje: singleTitular.cmptd_nuda_enaje,
                    ptit_usufructo: singleTitular.cmpdt_usufructo,
                    usufructo_enaje: singleTitular.cmptd_usufructo_enaje,
                    usufructo_adquiere: singleTitular.cmptd_usufructo_adquiere,
                    cmptd_representacion: singleTitular.cmptd_representacion,
                    ptit_status: singleTitular.cmpdt_movimiento == 3 ? 0 : 1,
                    folio: singleTitular.cmptd_valor,
                    prop_folio: singleTitular.prop_folio,
                    prop_id: singleTitular.cmptd_valor
                }
            })
            props.setTitularesCollection(newTitulares)
            const result = {};

            newTitulares.forEach(item => {
                if (!result[item.folio]) {
                    result[item.folio] = {
                        folio: item.folio,
                        nuda: 0,
                        nudaEnaje: 0,
                        usoFructo: 0,
                        usoFructoEnaje: 0
                    };
                }
                result[item.folio].nuda = result[item.folio].nuda + parseFloat(item.ptit_nuda || 0.00) + parseFloat(item.nuda_adquiere || 0.00)
                result[item.folio].nudaEnaje = result[item.folio].nudaEnaje + parseFloat(item.nuda_enaje || 0.00)
                result[item.folio].usoFructo = result[item.folio].usoFructo + parseFloat(item.ptit_usufructo || 0.00) + parseFloat(item.usufructo_adquiere || 0.00)
                result[item.folio].usoFructoEnaje = result[item.folio].usoFructoEnaje + parseFloat(item.usufructo_enaje || 0.00)
            });

            Object.values(result).forEach((folio: any) => {
                const { nuda, usoFructo } = folio;
                setTotalNuda(nuda)
                setTotalUso(usoFructo)
            });
            props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
        }
    }

    const [standAlone, setStandAlone] = useState(false)

    const getTitularesSingle = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                handleMultipleFoliosValues()
                formattTitulares(res.data.addenda)
            } else {
                handleMultipleFoliosValues()
            }
        }).catch(error => {

        })
    }

    const getTitularesReturn = (prop_id) => {
        let datos = {
            prop_id: prop_id
        }
        return ApiServices.apiPost('getTitularesFolio', datos)
    }

    const getTitulares = () => {
        let datos = {
            cmp_id: props.cmpId,
            frmu_id: props.responseData ? props.responseData.ptrm_id : (props.information?.frmu_id || props.preTramiteFolio?.ptrm_id),
        }
        ApiServices.apiPost('getTitularesActualiza', datos).then(async res => {
            let dataEx = res.data.addenda
            if (dataEx && dataEx.length > 0) {
                handleMultipleFoliosValues()
                formattTitulares(res.data.addenda)
            } else {
                getTitularesFolio()
            }
        }).catch(error => {

        })
    }

    const getTitularesFolio = () => {
        let datos = {
            prop_id: props.preTramiteDetail ? props.preTramiteDetail.tramite.prop_id :
                props.responseData ? props.responseData.prop_id :
                    props.previousFolio ? props.previousFolio[0].prop_id :
                        props.generalData ? props.generalData.prop_id :
                            props.propiedadDetail ? props.propiedadDetail.prop_id :
                                0
        }
        ApiServices.apiPost('getTitularesFolio', datos).then(async res => {
            if (props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0) {
                handleMultipleFolios(res.data.addenda)
            } else {
                handleMultipleFolios(res.data.addenda)
            }
        }).catch(error => {
            console.log(error)
        })
    }

    const [allFolios, setAllFolios] = useState([])

    useEffect(() => {
        handleNewPoolSelect()
    }, [props.titularesCollection])

    const handleNewPoolSelect = () => {
        let newChilds = []
        props.titularesCollection.forEach(titular => {
            if (!newChilds.some(child => child.prop_id == titular.prop_id)) {
                newChilds.push(titular);
            }
        });

        setAllFolios(newChilds)
    }

    useEffect(() => {
        if (!selectedFolio) setSelectedFolio(allFolios[0])
    }, [allFolios])

    const handleMultipleFolios = async (father) => {
        let allTitulares = []
        let newChilds = []
        let newFather = props.tramite.tramite
        newChilds.push(newFather)
        const temporal = father.map((single) => {
            single.folio = props?.tramite?.tramite?.prop_folio || 0
            single.prop_id = props?.tramite?.tramite?.prop_id || 0
            return single
        })

        allTitulares = [...allTitulares, ...temporal]

        for (const element of props.tramite.hijos) {
            const newElement = element
            const titulares = await getTitularesReturn(element.prop_id)
            if (titulares.data.addenda && titulares.data.addenda.length > 0) {
                const temporal = titulares && titulares.data.addenda.map((single) => {
                    single.folio = newElement?.prop_folio || 0
                    single.prop_id = newElement?.prop_id || 0
                    return single
                })

                allTitulares = [...allTitulares, ...temporal]
            }
            newChilds.push(newElement)
        }
        props.setTitularesCollection(allTitulares)
        props.handleValueChange(allTitulares, props.indexes[0], props.indexes[1])

        const result = {};

        allTitulares.forEach(item => {
            if (!result[item.folio]) {
                result[item.folio] = {
                    folio: item.folio,
                    nuda: 0,
                    nudaEnaje: 0,
                    usoFructo: 0,
                    usoFructoEnaje: 0
                };
            }
            result[item.folio].nuda = result[item.folio].nuda + parseFloat(item.ptit_nuda || 0.00) + parseFloat(item.nuda_adquiere || 0.00)
            result[item.folio].nudaEnaje = result[item.folio].nudaEnaje + parseFloat(item.nuda_enaje || 0.00)
            result[item.folio].usoFructo = result[item.folio].usoFructo + parseFloat(item.ptit_usufructo || 0.00) + parseFloat(item.usufructo_adquiere || 0.00)
            result[item.folio].usoFructoEnaje = result[item.folio].usoFructoEnaje + parseFloat(item.usufructo_enaje || 0.00)
        });

        Object.values(result).forEach((folio: any) => {
            const { nuda, usoFructo } = folio;
            setTotalNuda(validate100(nuda))
            setTotalUso(validate100(usoFructo))
        });
        setAllFolios(newChilds)
    }

    const validate100 = (value) => {
        const redondeado = Math.round(value * 100) / 100;
        if (redondeado == 100)
            return 100
        else return value
    }

    const handleMultipleFoliosValues = async () => {
        let newChilds = []
        let newFather = props?.tramite?.tramite
        newChilds.push(newFather)

        if (props && props.tramite && props.tramite.hijos)
            for (const element of props.tramite.hijos) {
                const newElement = element
                newChilds.push(newElement)
            }

        setSelectedFolio(newChilds[0])
        setAllFolios(newChilds)
    }

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const addNewTitular = () => {
        if (standAlone) {
            if (titulares.length > 0) {
                let TemporalTitulares = []
                allFolios && allFolios.map((singleFolio) => {

                    const lastObject = titulares[titulares.length - 1];
                    const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                        acc[key] = '';
                        return acc;
                    }, {});

                    emptyObject.prop_id = singleFolio.prop_id
                    emptyObject.folio = singleFolio.prop_folio
                    emptyObject.ptit_id = makeid(10);
                    emptyObject.ptit_nom = data.nombre.value;
                    emptyObject.ptit_pat = data.paterno.value;
                    emptyObject.ptit_mat = data.materno.value;
                    emptyObject.ptit_rfc = data.rfc.value;
                    emptyObject.ptit_fecnac = '1980-03-23'
                    emptyObject.ptit_nuda = ''
                    emptyObject.ptit_usufructo = ''
                    emptyObject.usu_id = user.usu_id.value
                    emptyObject.ptit_status = '1'
                    emptyObject.cmptdt_id = null

                    setData({
                        cmpdt_concepto: { value: '', valid: false },
                        paterno: { value: '', valid: false },
                        materno: { value: '', valid: false },
                        nombre: { value: '', valid: false },
                        rfc: { value: '', valid: false },
                        curp: { value: '', valid: false },
                        nuda: { value: 0, valid: false },
                        usufructo: { value: 0, valid: false },
                        representacion: { value: '', valid: false },
                    })
                    TemporalTitulares.push(emptyObject)

                })
                props.setTitularesCollection([...titulares, ...TemporalTitulares]);
                props.handleValueChange([...titulares, TemporalTitulares], props.indexes[0], props.indexes[1])
            } else {
                let TemporalTitulares = []
                allFolios && allFolios.map((singleFolio) => {
                    const newTitular = {
                        prop_id: singleFolio ? singleFolio.prop_id : 0,
                        folio: singleFolio ? singleFolio.prop_folio : 0,
                        ptit_id: makeid(10),
                        ptit_fecnac: '1980-03-23',
                        cmpdt_concepto: "",
                        cmptdt_id: "",
                        ptit_nom: "",
                        ptit_pat: "",
                        ptit_mat: "",
                        ptit_rfc: "",
                        ptit_nuda: '',
                        cmpdt_curp: "",
                        nuda_enaje: '',
                        ptit_usufructo: '',
                        usufructo_enaje: '',
                        cmptd_representacion: "",
                        ptit_status: 1,
                        usu_id: user.usu_id.value
                    }
                    TemporalTitulares.push(newTitular)
                })
                props.setTitularesCollection([...titulares, ...TemporalTitulares]);
                props.handleValueChange([...titulares, TemporalTitulares], props.indexes[0], props.indexes[1])
            }
        } else {
            if (titulares.length > 0) {

                const lastObject = titulares[titulares.length - 1];
                const emptyObject: any = Object.keys(lastObject).reduce((acc, key) => {
                    acc[key] = '';
                    return acc;
                }, {});

                emptyObject.prop_id = selectedFolio.prop_id
                emptyObject.folio = selectedFolio.prop_folio
                emptyObject.ptit_id = makeid(10);
                emptyObject.ptit_nom = data.nombre.value;
                emptyObject.ptit_pat = data.paterno.value;
                emptyObject.ptit_mat = data.materno.value;
                emptyObject.ptit_rfc = data.rfc.value;
                emptyObject.ptit_fecnac = '1980-03-23'
                emptyObject.ptit_nuda = ''
                emptyObject.ptit_usufructo = ''
                emptyObject.usu_id = user.usu_id.value
                emptyObject.ptit_status = '1'
                emptyObject.cmptdt_id = null

                setData({
                    cmpdt_concepto: { value: '', valid: false },
                    paterno: { value: '', valid: false },
                    materno: { value: '', valid: false },
                    nombre: { value: '', valid: false },
                    rfc: { value: '', valid: false },
                    curp: { value: '', valid: false },
                    nuda: { value: 0, valid: false },
                    usufructo: { value: 0, valid: false },
                    representacion: { value: '', valid: false },
                })

                props.setTitularesCollection([...titulares, emptyObject]);
                props.handleValueChange([...titulares, emptyObject], props.indexes[0], props.indexes[1])
            } else {
                const newTitular = {
                    prop_id: selectedFolio ? selectedFolio.prop_id : 0,
                    folio: selectedFolio ? selectedFolio.prop_folio : 0,
                    ptit_id: makeid(10),
                    ptit_fecnac: '1980-03-23',
                    cmpdt_concepto: "",
                    cmptdt_id: "",
                    ptit_nom: "",
                    ptit_pat: "",
                    ptit_mat: "",
                    ptit_rfc: "",
                    ptit_nuda: '',
                    cmpdt_curp: "",
                    nuda_enaje: '',
                    ptit_usufructo: '',
                    usufructo_enaje: '',
                    cmptd_representacion: "",
                    ptit_status: 1,
                    usu_id: user.usu_id.value
                }
                props.setTitularesCollection([...titulares, newTitular]);
                props.handleValueChange([...titulares, newTitular], props.indexes[0], props.indexes[1])
            }
        }
    }

    const isDeletedTemplate = (rowData, index) => {
        if (rowData && rowData.ptit_status == 0)
            return <Tag severity="danger" value="Se Eliminará"></Tag>
        else return ''
    };

    const textEditor = (options: ColumnEditorOptions) => {
        if (
            options.field == 'ptit_nuda' ||
            options.field == "ptit_usufructo" ||
            options.field == 'nuda_enaje' ||
            options.field == 'usufructo_enaje' ||
            options.field == 'nuda_adquiere' ||
            options.field == 'usufructo_adquiere'
        ) {
            return (
                <InputNumber value={options.value}
                    onValueChange={(e) => options.editorCallback(e.value)}
                    maxFractionDigits={4}
                    minFractionDigits={4}
                    min={0}
                    max={100}
                />
            )
        } else
            return <InputText
                type="text"
                value={options.value}
                onChange={(e) => options.editorCallback(e.target.value)}
                onKeyDown={(e) => e.stopPropagation()}
            />;
    };

    function duplicarFoliosConIDsOriginales(array, folioOrigen) {
        const elementosOriginales = array.filter(item => item.folio === folioOrigen);
        const elementosEliminados = array.filter(item => item.folio !== folioOrigen);
        const elementosDuplicados = elementosEliminados.map((eliminado, index) => {
            const original = elementosOriginales[index % elementosOriginales.length];
            return {
                ...original,
                folio: eliminado.folio,
                ptit_id: eliminado.ptit_id,
                prop_id: eliminado.prop_id
            };
        });

        return [...elementosOriginales, ...elementosDuplicados];
    }

    const [totalNuda, setTotalNuda] = useState(0.00)
    const [totalUso, setTotalUso] = useState(0.00)

    const handleChangeTitulares = (index: string | number, field: any, value: any, prop_folio: any) => {
        console.log('Entro Change')
        const newTitulares = [...titulares];
        if (field == 'nuda_enaje') {
            newTitulares[index] = {
                ...newTitulares[index],
                'ptit_nuda': (newTitulares[index].ptit_nuda - value) < 0 ? 0 : newTitulares[index].ptit_nuda - value
            };
        }
        if (field == 'usufructo_enaje') {
            newTitulares[index] = {
                ...newTitulares[index],
                'ptit_usufructo': (newTitulares[index].ptit_usufructo - value) < 0 ? 0 : newTitulares[index].ptit_usufructo - value
            };
        }
        newTitulares[index] = {
            ...newTitulares[index],
            [field]: value
        };
        if (prop_folio > 0) {
            newTitulares[index] = {
                ...newTitulares[index],
                prop_id: prop_folio
            };
        }

        console.log('NEW TITULARES', newTitulares)

        props.handleDeleteTitularString(`${newTitulares[index].ptit_nom || ''} ${newTitulares[index].ptit_pat || ''} ${newTitulares[index].ptit_mat || ''}`)

        if (standAlone) {
            const newNewTitulares = duplicarFoliosConIDsOriginales(newTitulares, newTitulares[index].folio)
            props.setTitularesCollection(newNewTitulares);
            props.handleValueChange(newNewTitulares, props.indexes[0], props.indexes[1])
        } else {
            props.setTitularesCollection(newTitulares);
            props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1])
        }

        const result = {};

        newTitulares.forEach(item => {
            if (!result[item.folio]) {
                result[item.folio] = {
                    folio: item.folio,
                    nuda: 0,
                    nudaEnaje: 0,
                    usoFructo: 0,
                    usoFructoEnaje: 0
                };
            }
            result[item.folio].nuda = result[item.folio].nuda + parseFloat(item.ptit_nuda || 0.00) + parseFloat(item.nuda_adquiere || 0.00)
            result[item.folio].nudaEnaje = result[item.folio].nudaEnaje + parseFloat(item.nuda_enaje || 0.00)
            result[item.folio].usoFructo = result[item.folio].usoFructo + parseFloat(item.ptit_usufructo || 0.00) + parseFloat(item.usufructo_adquiere || 0.00)
            result[item.folio].usoFructoEnaje = result[item.folio].usoFructoEnaje + parseFloat(item.usufructo_enaje || 0.00)
        });

        Object.values(result).forEach((folio: any) => {
            const { nuda, usoFructo } = folio;

            setTotalNuda(validate100(nuda))
            setTotalUso(validate100(usoFructo))

        });

    };

    const findIndexByPtitId = (ptitId) => {
        return titulares.findIndex(item => item.ptit_id === ptitId);
    };

    const findIndexByCMPTDT = (cmptdt_id) => {
        return titulares.findIndex(item => item.cmptdt_id === cmptdt_id);
    };

    const onCellEditComplete = (e: { rowIndex: any; field: any; newValue: any; rowData: any; }) => {
        const cloneTitulares = JSON.parse(JSON.stringify(titulares));
        const row = e.rowData.ptit_id ? findIndexByPtitId(e.rowData.ptit_id) : findIndexByCMPTDT(e.rowData.cmptdt_id)
        if (
            e.field == 'ptit_nuda' ||
            e.field == "ptit_usufructo" ||
            e.field == 'nuda_enaje' ||
            e.field == 'usufructo_enaje' ||
            e.field == 'nuda_adquiere' ||
            e.field == 'usufructo_adquiere'
        ) {
            props.handleDeleteTitularString(`${cloneTitulares[row].ptit_nom || ''} ${cloneTitulares[row].ptit_pat || ''} ${cloneTitulares[row].ptit_mat || ''}`);
        }

        if (e.field == 'prop_folio') {
            let datos = {
                prop_folio: e.newValue,
                prop_online: 0
            }
            ApiServices.apiPost('getBuscaFolio', datos).then(async res => {
                if (res.data.valido === 1) {
                    if (res.data.addenda && res.data.addenda.length > 0) {
                        handleChangeTitulares(row, e.field, e.newValue, res.data.addenda[0].prop_id)
                    }
                }
            }).catch(error => {
                console.log(error)
            })
        } else
            handleChangeTitulares(row, e.field, e.newValue, 0)


    };

    function isNumeric(value) {
        return /^-?\d+$/.test(value);
    }

    const deleteTitular = (data, index) => {
        const row = data.ptit_id ? findIndexByPtitId(data.ptit_id) : findIndexByCMPTDT(data.cmptdt_id)
        const cloneTitulares = JSON.parse(JSON.stringify(titulares));
        if (isNumeric(data.ptit_id) || isNumeric(data.cmptdt_id)) {
            cloneTitulares[row].nuda_enaje = cloneTitulares[row].ptit_nuda;
            cloneTitulares[row].usufructo_enaje = cloneTitulares[row].ptit_usufructo;
            cloneTitulares[row].ptit_nuda = 0;
            cloneTitulares[row].ptit_usufructo = 0;
            cloneTitulares[row].ptit_status = 0;
            props.handleDeleteTitularString(`${cloneTitulares[row].ptit_nom || ''} ${cloneTitulares[row].ptit_pat || ''} ${cloneTitulares[index].ptit_mat || ''}`);
            if (standAlone) {
                const newNewTitulares = duplicarFoliosConIDsOriginales(cloneTitulares, cloneTitulares[index].folio)
                props.setTitularesCollection(newNewTitulares);
                props.handleValueChange(newNewTitulares, props.indexes[0], props.indexes[1]);
            } else {
                props.setTitularesCollection(cloneTitulares);
                props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1]);
            }
        } else {
            if (standAlone) {
                const newTitulares = cloneTitulares.filter(titular => titular.ptit_id !== data.ptit_id);
                const newNewTitulares = duplicarFoliosConIDsOriginales(newTitulares, newTitulares[index].folio)
                props.setTitularesCollection(newNewTitulares);
                props.handleValueChange(newNewTitulares, props.indexes[0], props.indexes[1]);
            } else {
                const newTitulares = cloneTitulares.filter(titular => titular.ptit_id !== data.ptit_id);
                props.setTitularesCollection(newTitulares);
                props.handleValueChange(newTitulares, props.indexes[0], props.indexes[1]);
            }
        }
    };

    const recoverTitular = (data, index) => {
        const row = data.ptit_id ? findIndexByPtitId(data.ptit_id) : findIndexByCMPTDT(data.cmptdt_id)
        const cloneTitulares = JSON.parse(JSON.stringify(titulares))
        if (isNumeric(data.ptit_id) || isNumeric(data.cmptdt_id)) {
            cloneTitulares[row].ptit_nuda = cloneTitulares[row].nuda_enaje
            cloneTitulares[row].ptit_usufructo = cloneTitulares[row].usufructo_enaje
            cloneTitulares[row].nuda_enaje = 0
            cloneTitulares[row].usufructo_enaje = 0
            cloneTitulares[row].ptit_status = 1
        } else {
            cloneTitulares.splice(row, 1);
        }
        if (standAlone) {
            const newNewTitulares = duplicarFoliosConIDsOriginales(cloneTitulares, cloneTitulares[index].folio)
            props.setTitularesCollection(newNewTitulares);
            props.handleValueChange(newNewTitulares, props.indexes[0], props.indexes[1]);
        } else {
            props.setTitularesCollection(cloneTitulares);
            props.handleValueChange(cloneTitulares, props.indexes[0], props.indexes[1]);
        }
    }

    const actionBodyTemplate = (rowData, index) => {
        const [visible, setVisible] = useState(false);
        const toast = useRef(null);
        const buttonEl = useRef(null);

        if (rowData && rowData.ptit_status == 0)
            return <Button type="button" icon="pi pi-history" rounded onClick={() => { recoverTitular(rowData, index.rowIndex) }}></Button>;
        else
            return (
                <>
                    <ConfirmPopup
                        target={buttonEl.current}
                        visible={visible}
                        onHide={() => setVisible(false)}
                        message="¿Estás seguro de eliminar?"
                        acceptLabel='Eliminar'
                        rejectLabel='Cancelar'
                        icon="pi pi-exclamation-triangle"
                        accept={() => { deleteTitular(rowData, index.rowIndex) }}
                    />

                    <Button
                        type="button"
                        severity="danger"
                        icon="pi pi-trash"
                        ref={buttonEl}
                        rounded onClick={() => { setVisible(true) }}>
                    </Button>
                </>
            );
    };

    const headerTemplate = (options) => {
        const className = `${options.className} justify-content-space-between`;

        return (
            <div className={className}>
                <div className="flex align-items-center gap-2">
                    <span className="font-bold">Adjudica en favor de:</span>
                </div>
                <div>
                    <Button onClick={addNewTitular}>
                        Agregar
                    </Button>
                </div>
            </div>
        );
    };

    const [selectedFolio, setSelectedFolio] = useState(null)

    const header = (
        <div className="flex flex-wrap align-items-center justify-content-between gap-2">
            {props && props.tramite && props.tramite.hijos && props.tramite.hijos.length > 0 ?
                <Dropdown value={selectedFolio} onChange={(e) => setSelectedFolio(e.value)} options={allFolios} optionLabel="prop_folio" placeholder="Folio" />
                : <span className="text-xl text-900 font-bold"></span>
            }
            <Button icon="pi pi-plus" rounded raised onClick={addNewTitular} />
        </div>
    );

    useEffect(() => {
        console.log('Entro')
        if (props && props.frm && props.frm.cmp_funcion && props.frm.cmp_funcion == 'titulares') {
            console.log('1')
            getTitulares()
        }
        else {
            console.log('2')
            getTitularesSingle()
        }
    }, [])

    const footerGroup = (
        <ColumnGroup>
            <Row>
                <Column footer="Total:" colSpan={10} footerStyle={{ textAlign: 'right' }} />
                <Column footer={totalNuda.toFixed(4)} />
                <Column />
                <Column />
                <Column footer={totalUso.toFixed(4)} />
            </Row>
        </ColumnGroup>
    );


    const visibleData = titulares.filter((item) => item?.prop_id == selectedFolio?.prop_id)
    return (
        <section className='dynamicStyle'>
            <Panel header={props && props.frm && props.frm.cmp_titulo ? props.frm.cmp_titulo : props && props.frm && props.frm.cmp_valores ? props.frm.cmp_valores : ''}>
                <div className='p-datatable p-component p-datatable-responsive-scroll datatable-responsive'>
                    <DataTable
                        value={visibleData}
                        dataKey="ptit_id"
                        paginator
                        editMode="cell"
                        rows={100}
                        className="datatable-responsive"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} products"
                        emptyMessage="No hay titulares."
                        showGridlines
                        header={header}
                        footerColumnGroup={footerGroup}
                    >
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={isDeletedTemplate} />
                        {
                            columns.map(({ field, header }, index) => {
                                if (field == 'cmpdt_concepto')
                                    return <Column
                                        key={field}
                                        field={field}
                                        header={header}
                                        body={(rowData, { rowIndex }) => (
                                            <Dropdown value={titulares[rowIndex].cmpdt_concepto}
                                                onChange={(e) => handleChangeTitulares(rowIndex, 'cmpdt_concepto', e.value, 0)}
                                                options={[
                                                    { name: 'Aplicacion por gananciales', code: '1' },
                                                    { name: 'Herederos', code: '2' },
                                                    { name: 'Legatarios', code: '3' }
                                                ]} optionLabel="name"
                                                placeholder="Seleccionar" className="w-full md:w-14rem" />
                                        )
                                        }
                                    />
                                else
                                    return <Column
                                        key={field}
                                        field={field}
                                        header={header}
                                        editor={(options) => textEditor(options)}
                                        onCellEditComplete={onCellEditComplete}
                                    />;
                            })
                        }
                        <Column
                            headerStyle={{ textAlign: 'center' }}
                            bodyStyle={{ textAlign: 'center', overflow: 'visible' }}
                            body={actionBodyTemplate} />
                    </DataTable>
                </div>
            </Panel>
        </section >
    );

}

export default React.memo(I12Acto);